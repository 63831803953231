import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

import Button from "../Button/Button";
import Header from "../Header/Header";
import LabelValueField from "../LabelValueField/LabelValueField";
import { useEffect, useState } from "react";
import axios from "../../utils/helpers/axios";
import { ClipLoader } from "react-spinners";
import { formatTimestamp } from "../../utils/helpers/dateFormat";
import { setCommunityId } from "../../redux/features/communitySlice";

import ImportIcon from "../../assets/svg/importIcon";

export default function Job() {
  const { id, jid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [community, setCommunity] = useState({});
  const [jobDetails, setJobDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingJobLetter, setIsLoadingJobLetter] = useState(false);
  const [isLoadingCloseJob, setIsLoadingCloseJob] = useState(false);
  const [isLoadingBoard, setIsLoadingBoard] = useState(false);
  const [isLoadingAttorney, setIsLoadingAttorney] = useState(false);

  console.log(jobDetails, "karuna");

  const sendForBoardApproval = async () => {
    try {
      setIsLoadingBoard(true);

      const { data } = await axios.post("/api/job/sendJobToBoardForReview", {
        jobId: jid,
      });

      if (data.success) {
        toast.success(data.message);
        getJobDetails();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingBoard(false);
    }
  };

  const sendForAttorneyApproval = async () => {
    try {
      setIsLoadingAttorney(true);

      const { data } = await axios.post("/api/job/sendJobToAttorney", {
        jobId: jid,
      });

      if (data.success) {
        toast.success(data.message);
        getJobDetails();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingAttorney(false);
    }
  };

  const getJobDetails = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/job/summary?jobId=${jid}&communityId=${id}`
      );

      if (data.success) {
        setJobDetails(data.jobSummary[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const downloadJobLetter = async () => {
    try {
      setIsLoadingJobLetter(true);
      const { data } = await axios.get(
        `/api/job/downloadJobLetters?jobId=${jid}`
      );

      if (data.success) {
        getMediaURL(data.mediaId);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
      setIsLoadingJobLetter(false);
    }
  };

  const getMediaURL = async (media_id) => {
    try {
      const { data } = await axios.get(
        `/api/media/mediaURL?mediaId=${media_id}`
      );
      if (data.success) {
        window.open(data.media.s3PresignedURL, "_blank");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingJobLetter(false);
    }
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const closeJob = async () => {
    try {
      setIsLoadingCloseJob(true);
      const { data } = await axios.post("/api/job/close", {
        communityId: id,
        jobId: jid,
      });

      if (data.success) {
        toast.success(data.message);
        toast.success("Generating Letter in Progress");
        generateLetters();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);

      setIsLoadingCloseJob(false);
    }
  };

  const generateLetters = async () => {
    try {
      const { data } = await axios.post("/api/job/generateLetters", {
        jobId: jid,
      });

      if (data.success) {
        toast.success(data.message);
        getJobDetails();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingCloseJob(false);
    }
  };

  useEffect(() => {
    if (id && jid) getJobDetails();
  }, [id, jid]);

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  return (
    <div className="w-full h-full">
      <Header
        title={isLoading ? "" : jobDetails?.jobName + " Inspection"}
        buttonText={community?.legalName}
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
      />
      <div className="px-8 flex flex-col gap-4 h-[92%] mt-4">
        {isLoading && (
          <div className="flex justify-center">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}
        {!isLoading && (
          <div className="flex flex-col grow">
            <div className="p-4 flex flex-col gap-4">
              <div className="flex ">
                <LabelValueField
                  extraClass="w-full"
                  label="Job Name"
                  value={jobDetails.jobName}
                />

                <LabelValueField
                  extraClass="w-full"
                  label="User"
                  value={
                    jobDetails.userDetails?.firstName +
                    " " +
                    jobDetails.userDetails?.lastName
                  }
                />
              </div>

              <div className="flex">
                <LabelValueField
                  extraClass="w-full"
                  label="Status"
                  value={jobDetails.status}
                />
                {/* <LabelValueField
                  extraClass="w-full"
                  label="Reviewed"
                  // value={jobDetails.sentForBoardReview}
                /> */}
                {jobDetails.status === "board" && (
                  <LabelValueField
                    extraClass="w-full"
                    label="Board Decision"
                    value={jobDetails.boardDecision}
                  />
                )}
              </div>

              <div className="flex">
                <LabelValueField
                  extraClass="w-1/3"
                  label="Date Opened"
                  value={formatTimestamp(jobDetails.createdAt).split("\n")[0]}
                />
                <LabelValueField
                  extraClass="w-1/3"
                  label="Date Closed"
                  value={
                    formatTimestamp(jobDetails.jobCloseTimestamp).split("\n")[0]
                  }
                />
              </div>

              <div className="flex">
                <LabelValueField
                  extraClass="w-full"
                  label="# of New Violations in This Job"
                  value={
                    jobDetails.duringViolationsCount?.duringViolationsCount
                  }
                />
              </div>

              <div className="flex">
                <LabelValueField
                  extraClass="w-full"
                  label="Total Number of Violations Included in This Job"
                  value={
                    jobDetails.includedViolationsCount?.includedViolationsCount
                  }
                />
              </div>

              <div className="flex">
                <LabelValueField
                  extraClass="w-full"
                  label="Total Letters Generated"
                  value={jobDetails.lettersCount?.lettersCount}
                />
              </div>

              <div className="flex">
                <LabelValueField
                  extraClass="w-full"
                  label="No. of Properties Marked for the Attorney during this Job"
                  value={jobDetails.attorneyFlaggedCount}
                />
              </div>

              <Button
                Icon={ImportIcon}
                iconClass=" w-[1rem] h-[1rem]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full mb-4 w-fit"
                innerText="Download Letters"
                spinnerLight={true}
                isLoading={isLoadingJobLetter}
                disabled={isLoadingJobLetter || jobDetails.status !== "closed"}
                onClick={downloadJobLetter}
              />
            </div>

            <div className="flex justify-between mt-auto mb-2">
              <div className="flex gap-4">
                <Button
                  type="submit"
                  innerText="Close Job & Post Violations"
                  extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-sm capitalize text-primary-white"
                  disabled={
                    jobDetails.status === "closed" ||
                    jobDetails.status === "open" ||
                    jobDetails.status === "review" ||
                    jobDetails.status === "board" ||
                    isLoadingCloseJob
                  }
                  isLoading={isLoadingCloseJob}
                  spinnerLight={true}
                  onClick={closeJob}
                />

                <Button
                  type="submit"
                  innerText="Review job"
                  extraClass="shadow-button border-[0.5px] bg-primary-white text-sm py-2 px-4 rounded-full capitalize text-primary-teal"
                  disabled={
                    jobDetails.status === "closed" ||
                    jobDetails.status === "open" ||
                    jobDetails.status === "board_reviewed" ||
                    jobDetails.status === "reviewed" ||
                    isLoadingCloseJob
                  }
                  onClick={() => navigate(`/community/${id}/job/${jid}/review`)}
                />

                <Button
                  type="submit"
                  innerText="Delete Job"
                  extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white text-sm py-2 px-4 rounded-full capitalize text-primary-red"
                />
                <Button
                  type="submit"
                  innerText="Cancel"
                  extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white text-sm py-2 px-4 rounded-full capitalize text-primary-red"
                  onClick={() => navigate(`/community/jobs/${id}`)}
                />
              </div>

              <div className="flex gap-4">
                {(jobDetails.status === "board" ||
                  jobDetails.status === "board_reviewed") && (
                  <Button
                    type="submit"
                    innerText="Send for Board Approval"
                    extraClass="shadow-button border-[0.5px] bg-primary-white text-sm py-2 px-4 rounded-full capitalize text-primary-teal"
                    onClick={sendForBoardApproval}
                    isLoading={isLoadingBoard}
                    disabled={
                      isLoadingBoard || jobDetails.status === "board_reviewed"
                    }
                  />
                )}

                <Button
                  type="submit"
                  innerText="Send to attorney"
                  extraClass="shadow-button border-[0.5px] bg-primary-white text-sm py-2 px-4 rounded-full capitalize text-primary-teal"
                  onClick={sendForAttorneyApproval}
                  isLoading={isLoadingAttorney}
                  disabled={
                    !(
                      jobDetails?.status === "closed" &&
                      jobDetails?.lettersGenerated === "yes" &&
                      jobDetails?.generatedAttorneyLettersMediaId !== "" &&
                      jobDetails?.attorneyFlaggedCount > 0
                    ) || isLoadingAttorney
                  }
                  title={
                    jobDetails?.status === "closed" &&
                    jobDetails?.lettersGenerated === "yes" &&
                    jobDetails?.generatedAttorneyLettersMediaId !== ""
                      ? `Reminder send to  attorney ${
                          jobDetails?.numReminderToAssignedAttorney
                            ? jobDetails?.numReminderToAssignedAttorney
                            : 0
                        } times`
                      : ""
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
