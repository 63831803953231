import { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";

import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import PropertyInfoCard from "../../components/PropertyInfoCard/PropertyInfoCard";
import Header from "../../components/Header/Header";
import axios from "../../utils/helpers/axios";
import Pagination from "../../components/Pagination/Pagination";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import { setCommunityId } from "../../redux/features/communitySlice";
import Select from "../../components/Input/SelectInput";

import NumberSortIcon from "../../assets/svg/numberSortIcon";
import AddIcon from "../../assets/svg/addIcon";
import SearchIcon from "../../assets/svg/searchIcon";

export default function CommunityProperties() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();

  const [community, setCommunity] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortFields, setSortFields] = useState({
    field: "updatedAt",
    value: -1,
  });
  const [totalProperties, setTotalProperties] = useState();
  const [properties, setProperties] = useState([]);
  const [streets, setStreets] = useState([]);
  const [street, setStreet] = useState({});
  const [filterFields, setFilterFields] = useState({
    field: "",
    value: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSelectStreet = (id, data) => {
    setStreet(data);
    setFilterFields({
      field: "streetName",
      value: data._id,
    });
  };

  const getMailingAddress = (property) => {
    const ownerData = property?.propertyOwnerShip?.[0];
    const alternateAddressData = ownerData?.alternateAddress;

    if (ownerData?.isMailingAddressDifferent) {
      const mailingData = [
        alternateAddressData?.firstOwnerFirstName,
        alternateAddressData?.firstOwnerLastName,
        alternateAddressData?.streetNumber,
        alternateAddressData?.streetName,
        alternateAddressData?.city,
        alternateAddressData?.state,
        alternateAddressData?.zip,
      ];

      const check = mailingData?.every((item) => item);

      if (check)
        return `${alternateAddressData?.firstOwnerFirstName} ${
          alternateAddressData?.firstOwnerLastName
        }  ${
          alternateAddressData?.secondOwnerFirstName
            ? `& ${alternateAddressData?.secondOwnerFirstName} ${alternateAddressData?.secondOwnerLastName}`
            : ""
        } ${
          alternateAddressData?.streetNumber +
          " " +
          alternateAddressData?.streetName
        }
      ${alternateAddressData?.city}
      ${", " + alternateAddressData?.state}
      ${alternateAddressData?.zip}`;
    } else {
      const mailingData = [
        ownerData?.firstOwnerFirstName,
        ownerData?.firstOwnerLastName,
        property?.streetNumber,
        property?.streetName,
        property?.city,
        property?.state,
        property?.zip,
      ];
      const check = mailingData?.every((item) => item);

      if (check)
        return `${ownerData?.firstOwnerFirstName} ${
          ownerData?.firstOwnerLastName
        } ${
          ownerData?.secondOwnerFirstName
            ? `& ${ownerData?.secondOwnerFirstName}  ${ownerData?.secondOwnerLastName}`
            : ""
        } ${property?.streetNumber + " " + property?.streetName}
      ${property?.city}
      ${", " + property?.state}
      ${property?.zip} `;
    }
  };

  const getPropertyName = (property) => {
    const propertyOwnerShip = property?.propertyOwnerShip?.[0];

    return `${propertyOwnerShip?.firstOwnerFirstName} ${
      propertyOwnerShip?.firstOwnerLastName
    } ${
      propertyOwnerShip?.secondOwnerFirstName
        ? "& " + propertyOwnerShip?.secondOwnerFirstName
        : ""
    } ${
      propertyOwnerShip?.secondOwnerLastName
        ? propertyOwnerShip?.secondOwnerLastName
        : ""
    }`;
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProperties = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/property/?communityId=${id}&pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=${
          sortFields.field
        }&sortOrders=${sortFields.value}&${
          filterFields.field
            ? "filterFields=" +
              filterFields.field +
              "&filterValues=" +
              filterFields.value +
              "&"
            : ""
        }${searchQuery ? "searchQuery=" + searchQuery : ""}`
      );

      if (data.success) {
        setTotalProperties(data.matchedPropertiesCount);
        setProperties(data.properties);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getStreet = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/streets?communityId=${id}`
      );

      if (data.success) {
        setStreets(data.streets);
      }
    } catch (error) {
      console.error();
    }
  };

  useEffect(() => {
    if (id) getStreet();
  }, [id]);

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  useEffect(() => {
    getProperties();
  }, [rowsPerPage, currentPage, sortFields.value, filterFields.value, id]);

  useEffect(() => {
    if (searchQuery.length === 0) getProperties();
    if (searchQuery.length > 2) getProperties();
  }, [searchQuery]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  return (
    <div className="w-full">
      <Header
        title="Properties"
        buttonText={community?.legalName}
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="flex gap-4 items-center justify-between">
          <Select
            iconClass="absolute top-[22%] text-secondary-gray"
            placeholder="Select Street"
            inputFieldClass="placeholder:text-primary-gray-light"
            outerClass="w-full"
            extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-8"
            dropdownData={streets}
            fieldName={"_id"}
            handleChange={onSelectStreet}
            value={street._id}
          />

          <div className="min-w-fit flex items-center gap-3">
            {
              <NumberSortIcon
                onClick={() =>
                  setSortFields({
                    field: "streetNumber",
                    value: sortFields.value === -1 ? 1 : -1,
                  })
                }
              />
            }
            <Link
              className="flex items-center w-fit"
              to={`/community/${id}/property/add`}
            >
              <Button
                Icon={AddIcon}
                iconClass=" w-[15px] h-[15px]"
                extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full"
                innerText="Add New Property"
              />
            </Link>
          </div>
        </div>
        <div className="flex item-center gap-4">
          <Input
            iconClass="absolute left-4 text-secondary-gray"
            Icon={SearchIcon}
            placeholder="Search Property by Address / Street / Owner’s Name"
            inputFieldClass="placeholder:text-primary-gray-light ml-2"
            extraClass="relative w-full border-gray border-[1px] rounded-full px-8 py-[2px]"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            outerClass={"w-full"}
          />
        </div>
        <div className="overflow-auto min-h-[6rem] px-4 flex flex-col">
          {isLoading && (
            <div className="flex justify-center">
              <ClipLoader color={"#0080A2"} size={50} />
            </div>
          )}
          {!isLoading &&
            properties?.map((property) => {
              const propertyOwnerShip = property?.propertyOwnerShip?.[0];

              return (
                <div
                  onClick={() =>
                    navigate(
                      `/community/${id}/property-details/${property._id}`
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <PropertyInfoCard
                    key={property?._id}
                    btnText={`${property?.streetNumber} 
                    ${property?.streetName} 
                    ${
                      property?.addressLine2
                        ? ", " + property?.addressLine2
                        : ""
                    }`}
                    name={getPropertyName(property)}
                    phone={propertyOwnerShip?.mobileNumber}
                    email={propertyOwnerShip?.email}
                    mailingAddress={getMailingAddress(property)}
                  />
                </div>
              );
            })}
          {!isLoading && properties?.length === 0 && (
            <div className="flex justify-center text-primary-gray">
              No property found
            </div>
          )}
        </div>

        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalProperties}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
          }}
        />
      </div>
    </div>
  );
}
