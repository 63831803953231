import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import Header from "../Header/Header";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Select from "../Input/SelectInput";
import TextToggleComponent from "../TextToggleComponent/TextToggleComponent";
import SwitchInputv2 from "../Input/SwitchInputv2";
import axios from "../../utils/helpers/axios";
import { ToggleSidebarContext } from "../Layout/Layout";
import { setCommunityId as setCommunityIdAction } from "../../redux/features/communitySlice";
import capitalizeInitials from "../../utils/helpers/capitalizeInitials";
import CropEasy from "../Crop/CropEasy";

import CloseIcon from "../../assets/svg/closeIcon";
import UploadIcon from "../../assets/svg/uploadIcon.svg";
import CompanyDashboardDefault from "../../assets/images/companyDashboard_default.png";
import { ClipLoader } from "react-spinners";

export default function AddCommunity() {
  const csvUploadRef = useRef("");
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();

  const [csvMediaId, setCSVMediaId] = useState("");
  const [communityData, setCommunityData] = useState({
    legalName: "",
    letterName: "",
    email: "",
    officeEmail: "",
    coverPicture: "",
    generateLetterForBothPropertyAndMailingAddress: false,
    multiplePhotosPerLetter: false,
    ownerAccess: false,
    boardReview: false,
    active: "true",
    managers: [],
  });
  const [file, setFile] = useState("");
  const [filePreview, setFilePreview] = useState("");
  const [cropImagePreview, setCropImagePreview] = useState("");
  const [assignedUsers, setAssignedUsers] = useState({
    addEmails: [],
    removeEmails: [],
  });
  const [assignedAttornies, setAssignedAttornies] = useState({
    addEmails: [],
    removeEmails: [],
  });
  const [companyUsers, setCompanyUsers] = useState([]);
  const [companyAttornies, setCompanyAttornies] = useState([]);
  const [communityId, setCommunityId] = useState("");
  const [assignedEmails, setAssignedEmails] = useState({
    users: [],
    attorneys: [],
  });
  const [assignedManager, setAssignedManager] = useState([]);
  const [filterCompanyManager, setFilterCompanyManager] = useState("");
  const [filterCompanyUsers, setFilterCompanyUsers] = useState("");
  const [filterCompanyAttorney, setFilterCompanyAttorney] = useState("");
  const [openCrop, setOpenCrop] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);
  const [isLoadingCSVUpload, setIsLoadingCSVUpload] = useState(false);
  const [isLoadingimportPropertyFromCSV, setIsLoadingimportPropertyFromCSV] =
    useState(false);

  function handleChange(e) {
    if (e.target.files[0]) {
      setFilePreview(URL.createObjectURL(e.target.files[0]));
      setOpenCrop(true);
    }
  }

  function csvhandleChange(e) {
    if (e.target.files[0]) {
      uploadCSV(e.target.files[0]);
      e.target.value = null;
    }
  }

  const uploadCSV = async (file) => {
    try {
      setIsLoadingCSVUpload(true);

      const { data } = await axios.post("/api/media/uploadURL/document", {
        fileName: file.name,
        mimeType: file.type,
      });

      if (data.success) {
        putCsvFile(data.s3PostPayload, data.mediaId, file);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingCSVUpload(false);
    }
  };

  function putCsvFile(url, media_id, csvfile) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "text/csv");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: csvfile,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then(() => ackUploadCSV(media_id))
      .catch((error) => {
        console.error(error);
        setIsLoadingCSVUpload(false);
      });
  }

  const ackUploadCSV = async (media_id) => {
    try {
      const { data } = await axios.post(`/api/media/ackMediaUpload`, {
        mediaId: media_id,
      });

      if (data.success) {
        toast.success("CSV uploaded successfully.", { duration: 7000 });
        setCSVMediaId(media_id);
        if (id) importPropertyFromCSV(media_id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingCSVUpload(false);
    }
  };

  const importPropertyFromCSV = async (media_id, communityId) => {
    try {
      setIsLoadingimportPropertyFromCSV(true);

      const { data } = await axios.post("/api/property/importPropertyFromCSV", {
        communityId: id ? id : communityId,
        mediaId: media_id,
      });

      if (data.success) {
        toast.success(data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message, { duration: 8000 });
    } finally {
      setIsLoadingimportPropertyFromCSV(false);
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    setCommunityData((prev) => ({ ...prev, [name]: value }));
  };

  const filterUsersByName = (users) => {
    return users.filter((user) =>
      user.firstName.toLowerCase().includes(filterCompanyUsers.toLowerCase())
    );
  };

  const filterAttorneyByName = (attornies) => {
    return attornies.filter((attorney) =>
      attorney.firstName
        .toLowerCase()
        .includes(filterCompanyAttorney.toLowerCase())
    );
  };

  const uploadLogo = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.post("/api/media/uploadURL/image", {
        fileName: file.name,
        mimeType: file.type,
      });

      if (data.success) {
        put(data.s3PostPayload, data.mediaId);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  function put(url, media_id) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/png");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then(() => ackUploadMedia(media_id))
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }

  const ackUploadMedia = async (media_id) => {
    try {
      const { data } = await axios.post(`/api/media/ackMediaUpload`, {
        mediaId: media_id,
      });

      if (data.success) {
        toast.success("Cover Image uploaded successfully.", { duration: 5000 });
        setCommunityData((prev) => ({ ...prev, coverPicture: data.media._id }));
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const addCommunity = async () => {
    try {
      const payload = communityData;
      if (!communityData.coverPicture) delete payload.coverPicture;
      if (assignedManager.length)
        payload.managers = assignedManager.map((manager) => manager._id);
      else delete payload.managers;

      const { data } = await axios.post("/api/community/add", payload);

      if (data.success) {
        toast.success("Community added successfully.", { duration: 5000 });
        setCommunityId(data.community_id);
        importPropertyFromCSV(csvMediaId, data.community_id);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message, { duration: 5000 });
    } finally {
      setIsLoading(false);
    }
  };

  const getComapnyUsers = async () => {
    try {
      const { data } = await axios.get(
        `/api/company/users?sortFields=updatedAt&sortOrders=-1`
      );

      if (data.success) setCompanyUsers(data.users);
    } catch (error) {
      console.error(error);
    }
  };

  const getComapnyAttornies = async () => {
    try {
      const { data } = await axios.get(
        `/api/company/attorneys?sortFields=updatedAt&sortOrders=-1`
      );

      if (data.success) setCompanyAttornies(data.attorneys);
    } catch (error) {
      console.error(error);
    }
  };

  const getAssignedUsers = async () => {
    try {
      const { data } = await axios.get(
        `api/company/users?sortFields=updatedAt&sortOrders=-1&filterFields=communitiesAllowed&filterValues=${id}`
      );

      if (data.success) {
        setAssignedUsers((prev) => ({
          ...prev,
          addEmails: data.users.map((user) => user.email),
        }));
        setAssignedEmails((prev) => ({
          ...prev,
          users: data.users.map((user) => user.email),
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAssignedAttornies = async () => {
    try {
      const { data } = await axios.get(
        `api/company/attorneys?sortFields=updatedAt&sortOrders=-1&filterFields=communitiesAllowed&filterValues=${id}`
      );

      if (data.success) {
        setAssignedAttornies((prev) => ({
          ...prev,
          addEmails: data.attorneys.map((attorney) => attorney.email),
        }));
        setAssignedEmails((prev) => ({
          ...prev,
          attorneys: data.attorneys.map((attorney) => attorney.email),
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCommunityById = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        const community = data.communities[0];

        setCommunityData(
          Object.keys(communityData).reduce((acc, key) => {
            if (key in community) acc[key] = community[key];
            return acc;
          }, {})
        );

        getMediaURL(community.coverPicture);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMediaURL = async (media_id) => {
    if (!media_id) return;

    const { data } = await axios.get(
      `/api/media/mediaURL?mediaId=${media_id}`,
      {
        mediaId: media_id,
      }
    );

    if (data.success) {
      setCropImagePreview(data.media.s3PresignedURL);
    }
  };

  const manageCommunityUsers = async () => {
    try {
      const { data } = await axios.post("/api/community/manageUser", {
        communityId: communityId || id,
        removeEmails: assignedUsers.removeEmails,
        addEmails: assignedUsers.addEmails.filter(
          (email) => !assignedEmails.users.includes(email)
        ),
      });

      if (data.success) {
        toast.success("Users added successfully.", { duration: 5000 });
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message, { duration: 5000 });
    }
  };

  const manageCommunityAttorney = async () => {
    try {
      const { data } = await axios.post("/api/community/manageAttorney", {
        removeEmails: assignedAttornies.removeEmails,
        addEmails: assignedAttornies.addEmails.filter(
          (email) => !assignedEmails.attorneys.includes(email)
        ),
        communityId: communityId || id,
      });

      if (data.success) {
        toast.success("Attornies added successfully.", { duration: 5000 });

        if (!id) navigate("/company/communities");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message, { duration: 5000 });
    }
  };

  const updateCommunity = async () => {
    try {
      setIsLoading(true);

      const payload = communityData;

      if (assignedManager.length)
        payload.managers = assignedManager.map((manager) => manager._id);
      else delete payload.managers;

      const { data } = await axios.patch("/api/community/update", {
        communityId: id,
        updateFields: payload,
      });

      if (data.success) {
        toast.success(data.message, { duration: 5000 });
        navigate(`/community/dashboard/${id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message, { duration: 5000 });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteCommunity = async () => {
    try {
      setIsLoadingDelete(true);

      const { data } = await axios.delete(
        `/api/community/delete?communityId=${id}`
      );

      if (data.success) {
        toast.success(data.message, { duration: 5000 });
        navigate("/company/communities");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message, { duration: 5000 });
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const downloadCSV = async () => {
    try {
      setIsLoadingCSV(true);

      const response = await axios.get(`/api/property/samplePropertyCSV`, {
        responseType: "arraybuffer",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "downloadedCSV.csv";
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
      toast.error(
        error.response?.data?.message || "Error downloading the CSV file"
      );
    } finally {
      setIsLoadingCSV(false);
    }
  };

  useEffect(() => {
    getComapnyUsers();
    getComapnyAttornies();
  }, []);

  useEffect(() => {
    if (communityData.coverPicture && !id) {
      addCommunity();
    }
    if (file && id) updateCommunity();
  }, [communityData.coverPicture]);

  useEffect(() => {
    if (communityId) {
      manageCommunityUsers();
      manageCommunityAttorney();
    }
  }, [communityId]);

  useEffect(() => {
    if (id) {
      getCommunityById();
      getAssignedAttornies();
      getAssignedUsers();
      dispatch(setCommunityIdAction(id));
    }
  }, [id]);

  useEffect(() => {
    if (companyUsers?.length && communityData.managers?.length) {
      setAssignedManager(
        companyUsers.filter((user) =>
          communityData?.managers.includes(user._id)
        )
      );
    }
  }, [companyUsers, communityData]);

  return (
    <div className="w-full">
      {openCrop && (
        <CropEasy
          {...{
            photoURL: filePreview,
            setPhotoURL: setCropImagePreview,
            openCrop: openCrop,
            setOpenCrop: setOpenCrop,
            setFile: setFile,
            aspect: 3.7,
          }}
        />
      )}
      <input
        type="file"
        className="hidden"
        ref={csvUploadRef}
        onChange={csvhandleChange}
        accept=".csv"
      />
      <Header
        title={id ? "Edit Community" : "Add Community"}
        buttonText={id ? communityData?.legalName : user.company.companyName}
        onClick={() => {
          if (!id) navigate("/companydashboard");
          else navigate(`/community/dashboard/${id}`);
        }}
      />
      <div
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
        onClick={() => setToggleSidebar(false)}
      >
        <div className="grid grid-cols-2 grow gap-[1.6rem] pb-4">
          <div className="flex flex-col gap-[1rem] w-full">
            <div className="flex flex-col justify-between space-y-4">
              <Input
                label="Community Cover Image"
                labelClass="text-primary-teal"
                type="file"
                inputFieldClass="px-4 placeholder-red-300 file:border-none file:bg-transparent file:text-primary-teal file:cursor-pointer "
                outerClass="w-full"
                extraClass="border-gray border-[1px] rounded-full"
                onChange={handleChange}
              />
              <div className="space-y-2">
                <div className="text-secondary-gray-light">Preview Image</div>
                <div className="w-[24rem] h-[6rem] border-[1px] border-gray rounded-xl shadow-inputShadow">
                  {cropImagePreview ? (
                    <img
                      src={cropImagePreview}
                      alt={CompanyDashboardDefault}
                      className="w-[24rem] rounded-xl h-[6rem] object-fit"
                    />
                  ) : (
                    <img
                      src={CompanyDashboardDefault}
                      className="w-[24rem] h-[6rem] object-contain rounded-xl"
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="py-2 space-y-2">
              <TextToggleComponent
                label="Generate letter for both property & mailing address?"
                info="If this toggle is off, letters will be generated for mailing only to the mailing address assigned for each property. If there is no alternate mailing address, the property address will default to the mailing address."
                isChecked={
                  communityData.generateLetterForBothPropertyAndMailingAddress
                }
                onChange={(id, value) => {
                  setCommunityData((prev) => ({
                    ...prev,
                    generateLetterForBothPropertyAndMailingAddress:
                      value === "add" ? true : false,
                  }));
                }}
                value={
                  communityData.generateLetterForBothPropertyAndMailingAddress
                }
              />
              <TextToggleComponent
                label="Allow multiple photos per letter?"
                info="If this toggle is on, multiple photos may be embedded in a letter. Photos will appear on page 2(+) of the letters. Four photos fit on a blank page.If the toggle is off, only one photo will be embedded. When the toggle is off, the application will only allow one photo per property. (Additional photos can be added through History)"
                isChecked={communityData.multiplePhotosPerLetter}
                onChange={(id, value) => {
                  setCommunityData((prev) => ({
                    ...prev,
                    multiplePhotosPerLetter: value === "add" ? true : false,
                  }));
                }}
                value={communityData.multiplePhotosPerLetter}
              />
              <TextToggleComponent
                label="Enable Owner Access Feature?"
                info="If this toggle is on, after a job is closed & posted, an email will be sent to the owner’s email on file telling them of activity on their property. This email will provide the owner a link to log in, view open issues, photos, history and leave a note for the inspector."
                isChecked={communityData.ownerAccess}
                onChange={(id, value) => {
                  setCommunityData((prev) => ({
                    ...prev,
                    ownerAccess: value === "add" ? true : false,
                  }));
                }}
                value={communityData.ownerAccess}
              />
              <TextToggleComponent
                label="Enable Board Review?"
                info="If this toggle is on, while an inspection job is in Review Status an email will be sent to the Board User to review the new job. If this toggle is on, Board approval is required before a job can be closed and letters generated. *A board user must be assigned to enable this feature."
                isChecked={communityData.boardReview}
                onChange={(id, value) => {
                  setCommunityData((prev) => ({
                    ...prev,
                    boardReview: value === "add" ? true : false,
                  }));
                }}
                value={communityData.boardReview}
              />
            </div>

            <div className="relative h-[7.5rem] rounded-xl shadow-inputShadow flex border-[1px] border-offWhite p-1 flex flex-col items-center gap-[0.2rem] bg-offWhite">
              <span className="text-primary-teal text-[0.8rem] absolute top-2 left-2">
                Property List Import
              </span>
              <img
                src={UploadIcon}
                className="h-[20px] w-[20px] object-contain"
                alt=""
              />
              <div className="flex flex-col items-center">
                <div className="text-primary-black text-[0.6rem]">
                  Drag and Drop to Upload Property List CSV
                </div>
                <span className="text-primary-black text-[0.6rem]">OR</span>
              </div>
              <div className="absolute bottom-2 flex flex-col items-center gap-[2px]">
                <Button
                  type="submit"
                  innerText="Browse File"
                  extraClass="shadow-button border-[0.5px] border-primary-white bg-primary-white rounded-full px-2 text-primary-teal text-[0.8rem]"
                  onClick={() => {
                    csvUploadRef.current.click();
                  }}
                  isLoading={isLoadingCSVUpload}
                  disabled={isLoadingCSVUpload}
                />
                <Button
                  type="submit"
                  innerText="Download Sample CSV"
                  extraClass="shadow-button border-[0.5px] border-primary-white bg-primary-white rounded-full px-2 text-primary-teal text-[0.8rem]"
                  onClick={downloadCSV}
                  isLoading={isLoadingCSV}
                  disabled={isLoadingCSV}
                />
              </div>
            </div>
            {isLoadingimportPropertyFromCSV && (
              <div className="flex gap-[1rem] justify-center items-center w-full mt-2">
                <ClipLoader color={"#0080A2"} size={30} />
                <span className="text-primary-teal text-[0.8rem]">
                  csv uploaded fetching property
                </span>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-[1rem] w-full">
            <Input
              label="Community's Legal Name"
              placeholder="Enter Community's Legal Name"
              type="text"
              name={"legalName"}
              labelClass="text-primary-teal"
              inputFieldClass="px-4"
              required={true}
              outerClass="w-full "
              extraClass="rounded-full"
              onChange={onChangeHandler}
              value={communityData.legalName}
            />
            <Input
              label="Community's Name on Letters"
              placeholder="Enter Community Name"
              type="text"
              name={"letterName"}
              onChange={onChangeHandler}
              labelClass="text-primary-teal"
              inputFieldClass="px-4"
              required={true}
              outerClass="w-full"
              extraClass="rounded-full"
              value={communityData.letterName}
            />
            <Input
              label="Community's Email Address"
              placeholder="Enter Community Email"
              type="text"
              name={"email"}
              onChange={onChangeHandler}
              labelClass="text-primary-teal"
              inputFieldClass="px-4"
              required={true}
              outerClass="w-full "
              extraClass="rounded-full"
              value={communityData.email}
              info={`This email address will be used when the "Community Email" tag is embedded in a letter.`}
            />
            <Input
              label="Office Notes Email"
              placeholder="Enter Office Email"
              type="text"
              name={"officeEmail"}
              onChange={onChangeHandler}
              labelClass="text-primary-teal"
              inputFieldClass="px-4"
              required={true}
              outerClass="w-full "
              extraClass="rounded-full"
              value={communityData.officeEmail}
              info={`This is the email which will used for Office Notes during and inspection.`}
            />

            <div className="flex flex-col w-full space-y-4">
              <Select
                label="Assigned Manager's Email"
                labelClass="text-primary-teal"
                type="text"
                dropdownData={filterUsersByName(
                  companyUsers.filter((c) => {
                    return !assignedManager.find(
                      (manager) => c.email === manager.email
                    );
                  })
                )}
                fieldName={"firstName,lastName,email,role"}
                id={"email"}
                handleChange={(id, data) => {
                  setAssignedManager((prev) => [...prev, data]);
                }}
                onHandleInputChange={(e) => {
                  setFilterCompanyManager(e.target.value);
                }}
                value={filterCompanyManager}
                placeholder="Select Manager"
                inputFieldClass="px-4 w-full"
                outerClass="w-full"
                extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
                info={`Users entered in this field will be copied on emails from the system that are sent to owners and attorneys.`}
              />
              {assignedManager.length > 0 && (
                <div className="w-full rounded-[1rem] bg-white border-2 border-[#f6f6f6] shadow-lg rounded-xl px-4 py-2 overflow-auto h-[8rem]">
                  {assignedManager.map((manager) => {
                    const user = companyUsers.find(
                      (c) => c.email === manager.email
                    );

                    const role =
                      user?.role === "company_admin"
                        ? "Company Administrator"
                        : capitalizeInitials(user?.role, "_");

                    if (!user) return <></>;

                    return (
                      <li
                        key={user.email}
                        className="p-1 flex justify-between items-center text-primary-teal text-[0.9rem] font-[400]"
                      >
                        <div className="flex gap-2 items-center">
                          <span>{user?.firstName + " " + user?.lastName}</span>
                          <span>{user.email}</span>
                          <span> {role}</span>
                        </div>
                        <CloseIcon
                          className="w-5 cursor-pointer"
                          onClick={() => {
                            setAssignedManager((prev) =>
                              prev.filter(
                                (manager) => user.email !== manager.email
                              )
                            );
                          }}
                        />
                      </li>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="flex flex-col w-full space-y-4">
                <Select
                  label="Assigned Users"
                  labelClass="text-primary-teal"
                  type="text"
                  required={true}
                  dropdownData={filterUsersByName(
                    companyUsers.filter((c) => {
                      return !assignedUsers.addEmails.includes(c.email);
                    })
                  )}
                  fieldName={"firstName,lastName,role"}
                  id={"email"}
                  handleChange={(id, data) => {
                    setAssignedUsers((prev) => ({
                      ...prev,
                      addEmails: [...prev.addEmails, data.email],
                    }));
                  }}
                  onHandleInputChange={(e) => {
                    setFilterCompanyUsers(e.target.value);
                  }}
                  value={filterCompanyUsers}
                  placeholder="Select Users"
                  inputFieldClass="px-4 w-full"
                  outerClass="w-full"
                  extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
                  info={`You must assign at least one user to the community at set-up. This community will only be accessible to assigned users. Company Administrators will be added by default. Additional users can be added later`}
                />
                {assignedUsers.addEmails.length > 0 && (
                  <div className="w-full rounded-[1rem] bg-white border-2 border-[#f6f6f6] shadow-lg rounded-xl px-4 py-2 overflow-auto h-[8rem]">
                    {assignedUsers.addEmails.map((email) => {
                      const user = companyUsers.find((c) => c.email === email);
                      const role =
                        user?.role === "company_admin"
                          ? "Company Administrator"
                          : capitalizeInitials(user?.role, "_");
                      if (!user) return <></>;

                      return (
                        <li
                          key={email}
                          className="p-1 flex justify-between items-center text-primary-teal text-[0.9rem] font-[400]"
                        >
                          <span>
                            {user.firstName + " " + user.lastName + ", " + role}
                          </span>
                          <CloseIcon
                            className="w-5 cursor-pointer"
                            onClick={() => {
                              setAssignedUsers((prev) => ({
                                removeEmails: [...prev.removeEmails, email],
                                addEmails: prev.addEmails.filter(
                                  (c) => c !== email
                                ),
                              }));
                            }}
                          />
                        </li>
                      );
                    })}
                  </div>
                )}
              </div>

              <div className="flex flex-col w-full space-y-4">
                <Select
                  label="Assigned Attorney"
                  labelClass="text-primary-teal"
                  type="text"
                  dropdownData={filterAttorneyByName(
                    companyAttornies.filter((c) => {
                      return !assignedAttornies.addEmails.includes(c.email);
                    })
                  )}
                  fieldName={"firstName,lastName"}
                  id={"attorney"}
                  handleChange={(id, data) => {
                    setAssignedAttornies((prev) => ({
                      ...prev,
                      addEmails: [...prev.addEmails, data.email],
                    }));
                  }}
                  onHandleInputChange={(e) => {
                    setFilterCompanyAttorney(e.target.value);
                  }}
                  value={filterCompanyAttorney}
                  placeholder="Select Attorney"
                  inputFieldClass="px-4 w-full"
                  outerClass="w-full"
                  extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
                  info={`When an attorney is assigned to a community, they will receive emails when a property that has been marked for 'Attorney Level' enforcement is updated during a job.  Attorneys are added from the company level by an administrator.`}
                />
                {assignedAttornies.addEmails.length > 0 && (
                  <div className="w-full w-full rounded-[1rem] bg-white border-2 border-[#f6f6f6] shadow-lg rounded-xl px-4 py-2 overflow-auto h-[8rem]">
                    {assignedAttornies.addEmails.map((email) => {
                      const attorney = companyAttornies.find(
                        (c) => c.email === email
                      );
                      if (!attorney) return <></>;

                      return (
                        <li
                          key={email}
                          className="p-1 flex justify-between items-center text-primary-teal text-[0.9rem] font-[400]"
                        >
                          <span>
                            {attorney.firstName + " " + attorney.lastName}
                          </span>
                          <CloseIcon
                            className="w-5 cursor-pointer"
                            onClick={() => {
                              setAssignedAttornies((prev) => ({
                                removeEmails: [...prev.removeEmails, email],
                                addEmails: prev.addEmails.filter(
                                  (c) => c !== email
                                ),
                              }));
                            }}
                          />
                        </li>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between pb-[4rem]">
          <div className="flex gap-[1rem] items-center">
            <div className="flex">
              <SwitchInputv2
                isChecked={communityData.active === "true"}
                onChange={(id, value) => {
                  if (value === "add")
                    setCommunityData((prev) => ({ ...prev, active: "true" }));

                  if (value === "remove")
                    setCommunityData((prev) => ({ ...prev, active: "false" }));
                }}
              />
              <div className="flex flex-col">
                <span>Active</span>
                <span className="text-primary-gray-light">
                  Community Status
                </span>
              </div>
            </div>
            {id && (
              <Button
                innerText="Delete Community"
                extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white px-4 rounded-full py-2 text-[0.8rem] uppercase text-primary-red"
                isLoading={isLoadingDelete}
                onClick={deleteCommunity}
              />
            )}
          </div>

          <div>
            <div className="flex gap-[1rem]">
              <Button
                innerText="Cancel"
                extraClass={
                  "shadow-button bg-primary-white px-4 border-[1px] border-primary-red rounded-full py-2 py-1 text-[0.9rem] text-primary-red"
                }
                onClick={() => navigate("/company/communities")}
              />
              {id ? (
                <Button
                  type="submit"
                  innerText="Submit"
                  isLoading={isLoading}
                  disabled={
                    isLoading ||
                    Object.entries(communityData).some(
                      ([key, value]) => key !== "coverPicture" && value === ""
                    ) ||
                    !assignedUsers.addEmails.length
                  }
                  extraClass="shadow-button bg-primary-teal rounded-full py-2 px-6 text-[0.9rem] uppercase text-primary-white"
                  onClick={() => {
                    file ? uploadLogo() : updateCommunity();
                    manageCommunityAttorney();
                    manageCommunityUsers();
                  }}
                  spinnerLight={true}
                />
              ) : (
                <Button
                  type="submit"
                  innerText="Submit"
                  isLoading={isLoading}
                  disabled={
                    isLoading ||
                    Object.entries(communityData).some(
                      ([key, value]) => key !== "coverPicture" && value === ""
                    ) ||
                    !assignedUsers.addEmails.length
                  }
                  extraClass="shadow-button bg-primary-teal rounded-full py-2 px-6 text-[0.9rem] uppercase text-primary-white"
                  onClick={file ? uploadLogo : addCommunity}
                  spinnerLight={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
