import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Modal from "react-responsive-modal";

import Sidebar from "../../components/Sidebar/Sidebar";
import Button from "../Button/Button";
import toast, { Toaster } from "react-hot-toast";
import { logout } from "../../redux/features/auth-slice";
import Footer from "../Footer/Footer";

export const ToggleSidebarContext = React.createContext();

const Layout = ({ children }) => {
  const auth = useSelector((store) => store.auth);
  const adminAuth = useSelector((store) => store.adminAuth);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const errorStatus = searchParams.get("error");
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const [toggleSidebar, setToggleSidebar] = useState(false);

  useEffect(() => {
    if (errorStatus === "403") setOpen(true);

    if (errorStatus === "401") {
      toast.error("Unauthorized, Please Login.");
      dispatch(logout());
      navigate("/");
    }
  }, [errorStatus]);

  return (
    <ToggleSidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
      <div
        className={`relative max-w-screen h-[calc(100vh-64px)] flex p-4 bg-primary-white`}
      >
        <div className={`relative z-10 w-full flex flex-col rounded-2xl`}>
          {(auth.token || adminAuth.token) && (
            <Sidebar
              toggleSidebar={toggleSidebar}
              setToggle={setToggleSidebar}
            />
          )}

          <div
            className={`flex py-2 w-full h-full bg-primary-white rounded-bl-xl`}
          >
            {children}
          </div>
        </div>
      </div>
      <Footer />
      <Toaster position="bottom-left" reverseOrder={false} />
      <Modal
        showCloseIcon={false}
        blockScroll={true}
        open={open}
        onClose={() => {}}
        center
      >
        <div className="shadow-inputShadow rounded-xl p-4 flex flex-col gap-4">
          <div className="text-primary-teal text-[1.6rem]">
            You are not authorised for these resources, please contact your
            administrator.
          </div>
          <div className="flex justify-end w-full text-sm gap-4">
            <Button
              onClick={() => {
                setOpen(false);
                navigate("/companyDashboard");
              }}
              innerText="Okay"
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-primary-white"
            />
          </div>
        </div>
      </Modal>
    </ToggleSidebarContext.Provider>
  );
};

export default Layout;
