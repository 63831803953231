import { useContext } from "react";

import HelpHeader from "../../components/Help/HelpHeader";
import { ToggleSidebarContext } from "../../components/Layout/Layout";

function Article() {
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  return (
    <div className="w-full relative">
      <HelpHeader />
      <div
        onClick={() => setToggleSidebar(false)}
        className="flex flex-col gap-4 px-[12rem] mt-6 -mb-[6rem]"
      >
        <div className="flex gap-2 ">
          <h2 className="text-primary-teal">{`Getting Started >`}</h2>
          <h2 className="text-primary-teal font-bold">Add new Properties</h2>
        </div>
        <p className="text-[0.9rem] text-secondary-gray">
          &#8226; Adding new properties to ProCam INSPECT is essential for
          managing and scheduling inspections effectively. This guide will walk
          you through the process step-by-step, with visual aids and a video
          tutorial for better understanding.
        </p>
        <div className="text-secondary-gray font-semibold ">
          Step-by-Step Guide
        </div>
        <div className="flex flex-col gap-2 pb-4">
          <div>
            <div className="text-secondary-gray font-semibold ">
              Step 1: Log in to ProCam INSPECT
            </div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "1.4rem",
                color: "#6A7383",
              }}
            >
              <li>Open the ProCam INSPECT app or web platform.</li>
              <li>Enter your login credentials and click "Login".</li>
            </ul>
          </div>
          <div>
            <div className="text-secondary-gray font-semibold ">
              Step 2: Navigate to the Community Management Section
            </div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "1.4rem",
                color: "#6A7383",
              }}
            >
              <li>After logging in, go to the main dashboard.</li>
              <li>Click on "Community Management" from the sidebar menu.</li>
            </ul>
          </div>
          <div>
            <div className="text-secondary-gray font-semibold ">
              Step 3: Access the Properties Tab
            </div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "1.4rem",
                color: "#6A7383",
              }}
            >
              <li>
                In the Community Management section, click on the "Properties"
                tab.
              </li>
              <li>This will open the properties management interface.</li>
            </ul>
          </div>
          <div>
            <div className="text-secondary-gray font-semibold ">
              Step 4: Add New Property
            </div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "1.4rem",
                color: "#6A7383",
              }}
            >
              <li>Click on the "Add New Property" button.</li>
              <li>
                Fill in the required details such as property name, address, and
                other relevant information.
              </li>
            </ul>
          </div>
          <div>
            <div className="text-secondary-gray font-semibold ">
              Step 5: Save the Property
            </div>
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "1.4rem",
                color: "#6A7383",
              }}
            >
              <li>Review the entered details.</li>
              <li>Click "Save" to add the property to the community.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Article;
