import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import {
  formatDateToReadableString,
  formatTimestamp,
} from "../../utils/helpers/dateFormat";
import axios from "../../utils/helpers/axios";
import Button from "../../components/Button/Button";
import { login } from "../../redux/features/auth-slice";
import CustomTooltip from "../../components/Tooltip/Tooltip";

import SortingIcon from "../../assets/svg/sortingIcon";
import EditIcon from "../../assets/svg/editIcon";
import DashboardIcon from "../../assets/svg/dashboardIcon";
import CompanyLogo from "../../assets/images/companyDashboard_default.png";
import axiosAdmin from "../../utils/helpers/adminAxios";
import FlagIcon from "../../assets/svg/flagIcon";
import InfoIcon from "../../assets/svg/infoIcon.svg";
import capitalizeInitials, {
  capitalizeInitialsv2,
  capitalizeInitialsv3,
  capitalizeInitialsv4,
} from "../../utils/helpers/capitalizeInitials";
import { OAUTH_FALLBACK_URL } from "../../utils/helpers/url";
import toast from "react-hot-toast";

export const companyColumns = [
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Company Name</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "companyName",
    Cell: ({ row }) => {
      const [open, setOpen] = useState(false);
      const [filePreview, setFilePreview] = useState();

      const getMediaURL = async (media_id) => {
        const { data } = await axiosAdmin.get(
          `/admin/mediaURL?mediaId=${media_id}`
        );

        if (data.success) {
          setFilePreview(data.media.s3PresignedURL);
        }
      };

      useEffect(() => {
        if (open && row.original.logo) getMediaURL(row.original.logo);
      }, [open]);

      return (
        <div className="relative">
          <div
            onClick={() => {
              setOpen(true);
            }}
            className="text-primary-teal cursor-pointer"
          >
            {row.original.companyName}
          </div>
          <Modal
            focusTrapped={true}
            closeOnOverlayClick={true}
            showCloseIcon={false}
            blockScroll={true}
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            center
          >
            <div className="relative h-full w-full shadow-md rounded-lg p-4 flex justify-between items-center">
              <div className="flex items-center space-x-4 h-full w-full p-2">
                <div className="w-[28rem] h-[8rem] flex item-center shadow-md rounded-xl">
                  <img
                    src={filePreview || CompanyLogo}
                    alt="Logo"
                    className=" object-fit mr-4 rounded-xl"
                  />
                </div>
                <div className="h-full flex flex-col space-y-2 3xl:space-y-4">
                  <h2 className="text-xl font-semibold text-primary-teal">
                    {row.original.companyName}
                  </h2>
                  <div className="p-1 flex  flex-1 flex-col justify-around space-y-1 3xl:space-y-2">
                    <p className="text-md text-primary-gray">
                      No. of Communities:{" "}
                      <span className="text-sm">
                        {row.original.maxNumCommunities}
                      </span>
                    </p>
                    <p className="text-md text-primary-gray">
                      Subscription Type:{" "}
                      <span className="text-sm">
                        {row.original.subscriptionType}
                      </span>
                    </p>
                    <p className="text-md text-primary-gray">
                      Start:{" "}
                      <span className="text-sm">
                        {formatDateToReadableString(
                          row.original.subscriptionStartDate
                        )}
                      </span>
                    </p>
                    <p className="text-md text-primary-gray">
                      End:{" "}
                      <span className="text-sm">
                        {formatDateToReadableString(
                          row.original.subscriptionEndDate
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="absolute right-4 bottom-4 flex space-x-4">
                <Link
                  className="flex items-end w-fit h-full"
                  to={`/companies/edit/${row.original._id}`}
                >
                  <Button
                    Icon={EditIcon}
                    iconClass=" w-[12px] h-[12px]"
                    extraClass="relative text-primary-white bg-primary-teal py-2 px-4 text-xs rounded-full "
                    innerText="Edit Company"
                  />
                </Link>
              </div>
            </div>
          </Modal>
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3># of Communities</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "maxNumCommunities",
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Subscription Type</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "subscriptionType",
    Cell: ({ row }) => {
      return <div className="capitalize">{row.original.subscriptionType}</div>;
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Subscription Start</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "subscriptionStartDate",
    Cell: ({ row }) => {
      return (
        <div>
          {formatDateToReadableString(row.original.subscriptionStartDate)}
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Subscription End</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "subscriptionEndDate",
    Cell: ({ row }) => {
      return (
        <div>
          {formatDateToReadableString(row.original.subscriptionEndDate)}
        </div>
      );
    },
  },
];

export const UserColumns = [
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>First Name</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "firstName",
    Cell: ({ row }) => {
      const dispatch = useDispatch();
      const navigate = useNavigate();

      const [open, setOpen] = useState(false);
      const [filePreview, setFilePreview] = useState();
      const [isLoading, setIsLoading] = useState(false);

      const getMediaURL = async (media_id) => {
        const { data } = await axiosAdmin.get(
          `/admin/mediaURL?mediaId=${media_id}`
        );

        if (data.success) {
          setFilePreview(data.media.s3PresignedURL);
        }
      };

      const loginAPI = async () => {
        try {
          setIsLoading(true);

          const { data } = await axiosAdmin.post("/admin/proxyLogin", {
            userId: row.original._id,
          });

          if (data.success) {
            const user = data.userInfo;
            const token = data.token;

            dispatch(login({ token: token, user: user }));
            window.open("/", "_blank");
          }
        } catch (err) {
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      };

      useEffect(() => {
        if (open && row.original.avatar) getMediaURL(row.original.avatar);
      }, [open]);

      return (
        <div className="relative">
          <div
            onClick={() => {
              setOpen(true);
            }}
            className="text-primary-teal cursor-pointer"
          >
            {row.original.firstName}
          </div>
          <Modal
            focusTrapped={true}
            closeOnOverlayClick={true}
            showCloseIcon={false}
            blockScroll={true}
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            center
          >
            <div className="relative h-full w-full shadow-md rounded-lg p-4 flex justify-between items-center">
              <div className="flex space-x-4 h-full w-full p-2">
                <div className="w-1/5 flex item-center shadow-md rounded-xl">
                  <img
                    src={filePreview || CompanyLogo}
                    alt="Logo"
                    className=" object-fit rounded-xl mr-4 "
                  />
                </div>
                <div className="h-full flex flex-col space-y-2 3xl:space-y-4">
                  <h2 className="text-xl font-semibold text-primary-teal">
                    {row.original.firstName + " " + row.original.lastName}
                  </h2>
                  <div className="p-1 flex  flex-1 flex-col justify-around space-y-1 3xl:space-y-2">
                    <p className="text-md text-primary-gray">
                      login:{" "}
                      <span className="font-semibold">
                        {row.original.email}
                      </span>
                    </p>
                    <p className="text-md text-primary-gray">
                      company:{" "}
                      <span className="font-semibold">
                        {row.original.companyName}
                      </span>
                    </p>
                    <p className="text-md text-primary-gray">
                      role:{" "}
                      <span className="font-semibold">
                        {" "}
                        {row.original.role === "company_admin"
                          ? "Company Administrator"
                          : capitalizeInitials(row.original.role, "_")}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="absolute right-4 bottom-4 flex space-x-4">
                <div className="flex items-center w-fit">
                  <Button
                    Icon={DashboardIcon}
                    iconClass=" w-[20px] h-[20px]  lg:w-4 lg:h-4"
                    extraClass="relative text-primary-white bg-primary-teal py-2 lg:w-full px-4 py-[.5rem] 3xl:py-[0.7rem] text-sm lg:text-md 3xl:text-[1rem] rounded-full "
                    innerText="Proxy Login"
                    onClick={loginAPI}
                    disabled={isLoading}
                    isLoading={isLoading}
                    spinnerLight={true}
                  />
                </div>
                <Link
                  className="flex items-end w-fit h-full"
                  to={`/user/edit/${row.original._id}`}
                >
                  <Button
                    Icon={EditIcon}
                    iconClass=" w-[20px] h-[20px]  lg:w-4 lg:h-4"
                    extraClass="relative text-primary-white bg-primary-teal py-2 lg:w-full px-4 py-[.5rem] 3xl:py-[0.7rem] text-sm lg:text-md 3xl:text-[1rem] rounded-full "
                    innerText="Edit User"
                  />
                </Link>
              </div>
            </div>
          </Modal>
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Last Name</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "lastName",
    Cell: ({ row }) => {
      return (
        <div className="cursor-pointer text-primary-teal">
          {row.original.lastName}
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Login</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "email",
    Cell: ({ row }) => {
      return (
        <div className="cursor-pointer text-primary-teal">
          {row.original.email}
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Company</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "companyName",
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Role</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "role",
    Cell: ({ row }) => {
      return (
        <div className=" flex ">
          {row.original.role === "company_admin"
            ? "Company Administrator"
            : capitalizeInitials(row.original.role, "_")}
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Last Active</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "lastActive",
    Cell: ({ row }) => {
      return (
        <div className=" flex ">
          {row.original.lastActive
            ? formatDateToReadableString(row.original.lastActive)
            : "-"}
        </div>
      );
    },
  },
];

export const UserColumnsCompany = [
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>First Name</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "firstName",
    Cell: ({ row }) => {
      const [open, setOpen] = useState(false);
      const [filePreview, setFilePreview] = useState();

      const getMediaURL = async (media_id) => {
        const { data } = await axios.get(
          `/api/media/mediaURL?mediaId=${media_id}`
        );

        if (data.success) {
          setFilePreview(data.media.s3PresignedURL);
        }
      };

      const sendResetPassword = async () => {
        const url = `${OAUTH_FALLBACK_URL}/user`;

        try {
          const { data } = await axios.post(`/api/auth/forgotPassword`, {
            email: row.original.email,
            fallBackURL: url,
          });

          if (data.success)
            toast.success(
              "User will receive a link to the registered email address."
            );
        } catch (error) {
          console.error(error);
          toast.error(error.response.data.message);
        }
      };

      useEffect(() => {
        if (open && row.original.avatar) getMediaURL(row.original.avatar);
      }, [open]);

      return (
        <div className="relative">
          <div
            onClick={() => {
              setOpen(true);
            }}
            className="text-primary-teal cursor-pointer"
          >
            {row.original.firstName}
          </div>
          <Modal
            focusTrapped={true}
            closeOnOverlayClick={true}
            showCloseIcon={false}
            blockScroll={true}
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            center
          >
            <div className="relative h-full w-full shadow-md rounded-lg p-4 flex justify-between items-center">
              <div className=" flex space-x-4 h-full w-full p-2">
                <div className="w-1/5 border-[1px] border-gray rounded-xl shadow-inputShadow flex items-center">
                  <img
                    src={filePreview || CompanyLogo}
                    alt="Logo"
                    className=" object-fit rounded-xl mr-4"
                  />
                </div>
                <div className="h-full flex flex-col space-y-2 3xl:space-y-4">
                  <h2 className="text-xl font-semibold text-primary-teal">
                    {row.original.firstName + " " + row.original.lastName}
                  </h2>
                  <div className="p-1 flex  flex-1 flex-col justify-around space-y-1 3xl:space-y-2">
                    <p className="text-md text-primary-gray">
                      Login:{" "}
                      <span className="font-semibold">
                        {row.original.email}
                      </span>
                    </p>
                    <p className="text-md text-primary-gray">
                      Assigned Communities:{" "}
                      <span className="font-semibold">
                        {row.original.communitiesAllowed.includes("*")
                          ? "All"
                          : row.original.communitiesAllowed.length}
                      </span>
                    </p>
                    <p className="text-md text-primary-gray">
                      Role:{" "}
                      <span className="font-semibold">
                        {row.original.role === "company_admin"
                          ? "Company Administrator"
                          : capitalizeInitials(row.original.role, "_")}
                      </span>
                    </p>
                    <p className="text-md text-primary-gray">
                      Last Active:{" "}
                      <span className="font-semibold">
                        {row.original.lastActive
                          ? formatDateToReadableString(row.original.lastActive)
                          : " "}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="absolute right-4 bottom-4 flex space-x-4 flex">
                <Button
                  extraClass="text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full "
                  innerText="Send Reset Password Link"
                  onClick={sendResetPassword}
                />
                <Link
                  className="flex items-end w-fit h-full"
                  to={`/company/user/${row.original._id}/edit`}
                >
                  <Button
                    Icon={EditIcon}
                    iconClass=" w-[15px] h-[15px]"
                    extraClass="text-primary-white bg-primary-teal py-2 px-4 text-sm rounded-full "
                    innerText="Edit User"
                  />
                </Link>
              </div>
            </div>
          </Modal>
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Last Name</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "lastName",
    Cell: ({ row }) => {
      return (
        <div className="cursor-pointer text-primary-teal">
          {row.original.lastName}
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Login</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "email",
    Cell: ({ row }) => {
      return (
        <div className="cursor-pointer text-primary-teal">
          {row.original.email}
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Assigned Comunities</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "CommunitiesAllowed",
    Cell: ({ row }) => {
      return (
        <div className="cursor-pointer text-primary-teal">
          {row.original.communitiesAllowed.includes("*")
            ? "All"
            : row.original.communitiesAllowed.length}
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Role</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "role",
    Cell: ({ row }) => {
      return (
        <div className=" flex ">
          {row.original.role === "company_admin"
            ? "Company Administrator"
            : capitalizeInitials(row.original.role, "_")}
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Last Active</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "createdAt",
    Cell: ({ row }) => {
      return (
        <div>
          {row.original.lastActive
            ? formatDateToReadableString(row.original.lastActive)
            : "-"}
        </div>
      );
    },
  },
];

export const UserColumnsCommunity = (id) => {
  return [
    {
      Header: () => (
        <div className="flex items-center space-x-1.5">
          <h3>First Name</h3>
          <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
        </div>
      ),
      accessor: "firstName",
      Cell: ({ row }) => {
        const [open, setOpen] = useState(false);
        const [filePreview, setFilePreview] = useState();

        const getMediaURL = async (media_id) => {
          const { data } = await axios.get(
            `/api/media/mediaURL?mediaId=${media_id}`
          );

          if (data.success) {
            setFilePreview(data.media.s3PresignedURL);
          }
        };

        useEffect(() => {
          if (open && row.original.avatar) getMediaURL(row.original.avatar);
        }, [open]);

        return (
          <div className="relative">
            <div
              onClick={() => {
                setOpen(true);
              }}
              className="text-primary-teal cursor-pointer"
            >
              {row.original.firstName}
            </div>
            <Modal
              focusTrapped={true}
              closeOnOverlayClick={true}
              showCloseIcon={false}
              blockScroll={true}
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              center
            >
              <div className="relative h-full w-full shadow-md rounded-lg p-4 flex justify-between items-center">
                <div className="flex space-x-4 h-full w-full p-2">
                  <div className="w-1/5 flex item-center shadow-md rounded-xl">
                    <img
                      src={filePreview || CompanyLogo}
                      alt="Logo"
                      className=" object-contain mr-4 rounded-xl "
                    />
                  </div>
                  <div className="h-full flex flex-col space-y-2 3xl:space-y-4">
                    <h2 className="text-xl font-semibold text-primary-teal">
                      {row.original.firstName + " " + row.original.lastName}
                    </h2>
                    <div className="p-1 flex  flex-1 flex-col justify-around space-y-1 3xl:space-y-2">
                      <p className="text-md text-primary-gray">
                        login:{" "}
                        <span className="font-semibold">
                          {row.original.email}
                        </span>
                      </p>
                      <p className="text-md text-primary-gray">
                        Assigned Communities:{" "}
                        <span className="font-semibold">
                          {row.original.communitiesAllowed.includes("*")
                            ? "All"
                            : row.original.communitiesAllowed.length}
                        </span>
                      </p>
                      <p className="text-md text-primary-gray">
                        role:{" "}
                        <span className="font-semibold">
                          {row.original.role}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="absolute right-4 bottom-4 flex space-x-4">
                  <Link
                    className="flex items-end w-fit h-full"
                    to={`/community/${id}/user/${row.original._id}/edit`}
                  >
                    <Button
                      Icon={EditIcon}
                      iconClass=" w-[20px] h-[20px]  lg:w-4 lg:h-4"
                      extraClass="relative text-primary-white bg-primary-teal py-2 lg:w-full px-4 py-[.5rem] 3xl:py-[0.7rem] text-sm lg:text-md 3xl:text-[1rem] rounded-full "
                      innerText="Edit User"
                    />
                  </Link>
                </div>
              </div>
            </Modal>
          </div>
        );
      },
    },
    {
      Header: () => (
        <div className="flex items-center space-x-1.5">
          <h3>Last Name</h3>
          <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
        </div>
      ),
      accessor: "lastName",
      Cell: ({ row }) => {
        return (
          <div className="cursor-pointer text-primary-teal">
            {row.original.lastName}
          </div>
        );
      },
    },
    {
      Header: () => (
        <div className="flex items-center space-x-1.5">
          <h3>Login</h3>
          <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
        </div>
      ),
      accessor: "email",
      Cell: ({ row }) => {
        return (
          <div className="cursor-pointer text-primary-teal">
            {row.original.email}
          </div>
        );
      },
    },

    {
      Header: () => (
        <div className="flex items-center space-x-1.5">
          <h3>Role</h3>
          <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
        </div>
      ),
      accessor: "role",
    },
    {
      Header: () => (
        <div className="flex items-center space-x-1.5">
          <h3>Last Active</h3>
          <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
        </div>
      ),
      accessor: "createdAt",
      Cell: ({ row }) => {
        return (
          <div>
            {row.original.createdAt != undefined
              ? formatDateToReadableString(row.original.createdAt)
              : ""}
          </div>
        );
      },
    },
  ];
};

export const violationLibraryColumns = (
  onSelectHandler,
  selectedViolations,
  onEditHandler,
  violations,
  setSelectedViolations
) => {
  return [
    {
      Header: () => {
        return (
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={
                selectedViolations.length === violations.length &&
                violations.length !== 0
              }
              onClick={(e) => {
                if (e.target.checked)
                  setSelectedViolations(
                    violations.map((violation) => ({
                      id: violation._id,
                      title: violation.title,
                      content: violation.content,
                    }))
                  );
                else setSelectedViolations([]);
              }}
            />

            <span>Select All</span>
          </div>
        );
      },
      accessor: "select",
      disableSortBy: true,
      Cell: ({ row }) => (
        <input
          type="checkbox"
          onClick={(e) => onSelectHandler(e, row.original)}
          checked={
            selectedViolations.find(
              (selectedViolation) => selectedViolation.id === row.original._id
            )
              ? true
              : false
          }
        />
      ),
    },
    {
      Header: "Violation Title",
      accessor: "title",
      disableSortBy: true,
    },
    {
      Header: "Violation",
      accessor: "content",
      Cell: ({ row }) => (
        <div className="w-[90%] text-wrap">{row.original.content}</div>
      ),
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "EditIcon",
      Cell: ({ row }) => (
        <Button
          Icon={EditIcon}
          iconClass=" w-[1rem] h-[1rem]"
          extraClass="relative text-primary-white bg-primary-teal text-sm rounded-full px-4 py-2 m-1"
          innerText="Edit"
          onClick={() => onEditHandler(row.original)}
        />
      ),
      disableSortBy: true,
    },
  ];
};

export const violationLibraryCompanyColumns = (
  onSelectHandler,
  selectedViolations,
  onEditHandler,
  violations,
  setSelectedViolations,
  importFromMasterAdmin
) => {
  return [
    {
      Header: () => {
        return (
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={
                selectedViolations.length === violations.length &&
                violations.length !== 0
              }
              onClick={(e) => {
                if (e.target.checked)
                  setSelectedViolations(
                    violations.map((violation) => ({
                      id: violation._id,
                      title: violation.title,
                      content: violation.content,
                    }))
                  );
                else setSelectedViolations([]);
              }}
            />

            <span>Select All</span>
          </div>
        );
      },
      accessor: "select",
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="py-2">
          <input
            type="checkbox"
            onClick={(e) => onSelectHandler(e, row.original)}
            checked={
              selectedViolations.find(
                (selectedViolation) => selectedViolation.id === row.original._id
              )
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      Header: "Violation Title",
      accessor: "title",
      disableSortBy: true,
    },
    {
      Header: "Violation",
      accessor: "content",
      Cell: ({ row }) => (
        <div className="w-[90%] text-wrap">{row.original.content}</div>
      ),
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "EditIcon",
      Cell: ({ row }) => {
        if (importFromMasterAdmin) return null;
        return (
          <Button
            Icon={EditIcon}
            iconClass=" w-[1rem] h-[1rem]"
            extraClass="relative text-primary-white bg-primary-teal text-sm rounded-full px-4 py-2 m-1"
            innerText="Edit"
            onClick={() => onEditHandler(row.original)}
          />
        );
      },
      disableSortBy: true,
    },
  ];
};

export const violationLibraryCommunityColumns = (
  onSelectHandler,
  selectedViolations,
  onEditHandler,
  violations,
  setSelectedViolations,
  importFromCompanyAdmin
) => {
  return [
    {
      Header: () => {
        return (
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={
                selectedViolations.length === violations.length &&
                violations.length !== 0
              }
              onClick={(e) => {
                if (e.target.checked)
                  setSelectedViolations(
                    violations.map((violation) => ({
                      id: violation._id,
                      title: violation.title,
                      content: violation.content,
                    }))
                  );
                else setSelectedViolations([]);
              }}
            />

            <span>Select All</span>
          </div>
        );
      },
      accessor: "select",
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="py-2">
          <input
            type="checkbox"
            onClick={(e) => onSelectHandler(e, row.original)}
            checked={
              selectedViolations.find(
                (selectedViolation) => selectedViolation.id === row.original._id
              )
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      Header: "Violation Title",
      accessor: "title",
      disableSortBy: true,
    },
    {
      Header: "Violation",
      accessor: "content",
      Cell: ({ row }) => (
        <div className="w-[90%] text-wrap">{row.original.content}</div>
      ),
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "EditIcon",
      Cell: ({ row }) => {
        if (importFromCompanyAdmin) return null;
        return (
          <Button
            Icon={EditIcon}
            iconClass=" w-[1rem] h-[1rem]"
            extraClass="relative text-primary-white bg-primary-teal text-sm rounded-full px-4 py-2 m-1"
            innerText="Edit"
            onClick={() => onEditHandler(row.original)}
          />
        );
      },
      disableSortBy: true,
    },
  ];
};

export const letterLibraryAdminColumn = [
  {
    Header: "Select All",
    accessor: "select",
    type: "checkbox",
  },
  {
    Header: "Letter Name",
    accessor: "title",
  },
];

export const letterLibraryColumn = (
  selectedLetterLibraries,
  onSelectHandler,
  onEditHandler,
  letterLibraries,
  setSelectedLetterLibraries
) => {
  return [
    {
      Header: () => {
        return (
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={
                selectedLetterLibraries.length === letterLibraries.length &&
                letterLibraries.length !== 0
              }
              onClick={(e) => {
                if (e.target.checked)
                  setSelectedLetterLibraries(
                    letterLibraries.map((letter) => ({
                      id: letter._id,
                      title: letter.title,
                      content: letter.content,
                      margins: letter.margins,
                    }))
                  );
                else setSelectedLetterLibraries([]);
              }}
            />

            <span>Select All</span>
          </div>
        );
      },
      accessor: "select",
      disableSortBy: true,
      Cell: ({ row }) => (
        <input
          type="checkbox"
          onClick={(e) => onSelectHandler(e, row.original)}
          checked={
            selectedLetterLibraries.find(
              (selectedLetterLibrary) =>
                selectedLetterLibrary.id === row.original._id
            )
              ? true
              : false
          }
        />
      ),
    },
    {
      Header: "Letter Name",
      accessor: "title",
    },
    {
      Header: "",
      accessor: "EditIcon",
      Cell: ({ row }) => (
        <Button
          Icon={EditIcon}
          iconClass=" w-[1rem] h-[1rem]"
          extraClass="relative text-primary-white bg-primary-teal text-sm rounded-full px-4 py-2 m-1"
          innerText="Edit"
          onClick={() => onEditHandler(row.original)}
        />
      ),
    },
  ];
};

export const letterLibraryCompanyColumn = (
  selectedLetterLibraries,
  onSelectHandler,
  onEditHandler,
  letterLibraries,
  setSelectedLetterLibraries,
  importFromMasterAdmin
) => {
  return [
    {
      Header: () => {
        return (
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={
                selectedLetterLibraries.length === letterLibraries.length &&
                letterLibraries.length !== 0
              }
              onClick={(e) => {
                if (e.target.checked)
                  setSelectedLetterLibraries(
                    letterLibraries.map((letter) => ({
                      id: letter._id,
                      title: letter.title,
                      content: letter.content,
                      margins: letter.margins,
                    }))
                  );
                else setSelectedLetterLibraries([]);
              }}
            />

            <span>Select All</span>
          </div>
        );
      },
      accessor: "select",
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="py-2">
          <input
            type="checkbox"
            onClick={(e) => onSelectHandler(e, row.original)}
            checked={
              selectedLetterLibraries.find(
                (selectedLetterLibrary) =>
                  selectedLetterLibrary.id === row.original._id
              )
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      Header: "Letter Name",
      accessor: "title",
    },
    {
      Header: "",
      accessor: "EditIcon",
      Cell: ({ row }) => {
        if (importFromMasterAdmin) return null;
        return (
          <Button
            Icon={EditIcon}
            iconClass=" w-[1rem] h-[1rem]"
            extraClass="relative text-primary-white bg-primary-teal text-sm rounded-full px-4 py-2 m-1"
            innerText="Edit"
            onClick={() => onEditHandler(row.original)}
          />
        );
      },
    },
  ];
};

export const letterLibraryCommunityColumn = (
  selectedLetterLibraries,
  onSelectHandler,
  onEditHandler,
  letterLevels,
  importFromCompanyAdmin,
  letterLibraries,
  setSelectedLetterLibraries
) => {
  return [
    {
      Header: () => {
        return (
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={
                selectedLetterLibraries.length === letterLibraries.length &&
                letterLibraries.length !== 0
              }
              onClick={(e) => {
                if (e.target.checked)
                  setSelectedLetterLibraries(
                    letterLibraries.map((letter) => ({
                      id: letter._id,
                      title: letter.title,
                      content: letter.content,
                      margins: letter.margins,
                    }))
                  );
                else setSelectedLetterLibraries([]);
              }}
            />

            <span>Select All</span>
          </div>
        );
      },
      accessor: "select",
      disableSortBy: true,
      Cell: ({ row }) => (
        <div className="py-2">
          <input
            type="checkbox"
            onClick={(e) => onSelectHandler(e, row.original)}
            checked={
              selectedLetterLibraries.find(
                (selectedLetterLibrary) =>
                  selectedLetterLibrary.id === row.original._id
              )
                ? true
                : false
            }
          />
        </div>
      ),
    },
    {
      Header: "Name",
      accessor: "title",
      disableSortBy: true,
    },
    {
      Header: importFromCompanyAdmin ? "" : "Level",
      accessor: "level",
      Cell: ({ row }) => {
        if (importFromCompanyAdmin) return null;
        const level = letterLevels.find(
          (level) => level._id === row.original.level
        );
        return <div>{level ? level.title : ""}</div>;
      },
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "EditIcon",
      Cell: ({ row }) => {
        if (importFromCompanyAdmin) return null;
        return (
          <Button
            Icon={EditIcon}
            iconClass=" w-[20px] h-[20px] lg:h-4 3xl:h-5 3xl:w-5"
            extraClass="relative text-primary-white bg-primary-teal text-sm rounded-full px-4 py-2"
            innerText="Edit"
            onClick={() => onEditHandler(row.original)}
          />
        );
      },
      disableSortBy: true,
    },
  ];
};

export const ManageDropdownOptionColumn = (selectedRoles, onSelectHandler) => {
  return [
    {
      Header: () => (
        <div className="text-primary-teal font-semibold">Roles</div>
      ),
      accessor: "role",
      Cell: ({ row }) => {
        return (
          <div className="text-primary-teal flex items-center space-x-4 font-semibold">
            <div className="flex justify-start mt-1 ml-2 px-4">
              <input
                type="checkbox"
                onClick={(e) => onSelectHandler(e, row.original)}
                checked={
                  selectedRoles.find((role) => role === row.original._id)
                    ? true
                    : false
                }
                disabled={row.original.disabled}
              />
            </div>

            <span>{capitalizeInitialsv4(row?.original?.role, "_")}</span>
          </div>
        );
      },
    },
  ];
};

export const jobColumn = [
  {
    Header: "Job",
    accessor: "jobName",
    Cell: ({ row }) => {
      const navigate = useNavigate();

      return (
        <div
          className="text-primary-teal"
          onClick={() =>
            navigate(
              `/community/${row.original.community._id}/job/${row.original._id}/job-summary`
            )
          }
          style={{ cursor: "pointer" }}
        >
          {row.original.jobName}
        </div>
      );
    },
  },
  {
    Header: "Community",
    accessor: "letterName",
    sorting: true,
    Cell: ({ row }) => {
      return (
        <div className="text-primary-teal">
          {row.original.community?.letterName}
        </div>
      );
    },
  },

  {
    Header: "User",
    accessor: "name",
    Cell: ({ row }) => {
      return (
        <div className="text-primary-teal">
          {row.original.jobOpenedByUser?.name}
        </div>
      );
    },
  },
  {
    Header: "Opened",
    accessor: "createdAt",
    sorting: true,
    Cell: ({ row }) => {
      return (
        <div className="flex justify-start mt-1">
          <div className="flex flex-col">
            <span>
              {formatTimestamp(row.original.createdAt).split("\n")[0]}
            </span>
            <span>
              {formatTimestamp(row.original.createdAt).split("\n")[1]}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: "Closed",
    accessor: "jobCloseTimestamp",
    sorting: true,
    Cell: ({ row }) => {
      return (
        <div className="flex justify-start mt-1">
          <div className="flex flex-col">
            <span>
              {formatTimestamp(row.original.jobCloseTimestamp).split("\n")[0]}
            </span>
            <span>
              {formatTimestamp(row.original.jobCloseTimestamp).split("\n")[1]}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: "Status",
    accessor: "status",
    sorting: true,
    Cell: ({ row }) => {
      return (
        <div className="">
          <div className="text-primary-green capitalize">
            {row.original.status === "board_reviewed"
              ? "Board Reviewed"
              : row.original.status}
          </div>
        </div>
      );
    },
  },

  {
    Header: "Board Decision",
    accessor: "boardDecision",
    Cell: ({ row }) => {
      return (
        <div
          className={`capitalize break-words ${
            row?.original?.boardDecision === "NA"
              ? "text-primary-gray"
              : "text-primary-green"
          }`}
        >
          {row?.original?.boardDecision === "NA"
            ? "N/A"
            : row?.original?.boardDecision}
        </div>
      );
    },
  },
  {
    Header: "Sent to Attorney",
    accessor: "sentToAssignedAttorney",
    Cell: ({ row }) => {
      return (
        <div className="">
          <div
            className={`capitalize ${
              row.original.sentToAssignedAttorney === "yes"
                ? "text-primary-green"
                : "text-primary-gray"
            }`}
          >
            {row.original.sentToAssignedAttorney === "yes"
              ? "Not Sent to Attorney"
              : "N/A"}
          </div>
        </div>
      );
    },
  },
];

export const jobColumnCommunity = [
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Job</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "jobName",
    Cell: ({ row }) => {
      const navigate = useNavigate();
      return (
        <div
          className="text-primary-teal"
          onClick={() =>
            navigate(
              `/community/${row.original.community._id}/job/${row.original._id}/job-summary?communityAdmin=true`
            )
          }
          style={{ cursor: "pointer" }}
        >
          {row.original.jobName}
        </div>
      );
    },
  },

  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>User</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "name",
    Cell: ({ row }) => {
      return (
        <div className="text-primary-teal">
          {row.original.jobOpenedByUser.name}
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Opened</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "createdAt",
    sorting: true,
    Cell: ({ row }) => {
      return (
        <div className="flex justify-start mt-1">
          <div className="flex flex-col">
            <span>
              {formatTimestamp(row.original.createdAt).split("\n")[0]}
            </span>
            <span>
              {formatTimestamp(row.original.createdAt).split("\n")[1]}
            </span>
          </div>
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Closed</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "jobCloseTimestamp",
    sorting: true,
    Cell: ({ row }) => {
      return (
        <div className="flex justify-start mt-1">
          <div>
            <div className="flex items-center">
              <span>{formatTimestamp(row.original.jobCloseTimestamp)}</span>
            </div>
          </div>
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Status</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "status",
    sorting: true,
    Cell: ({ row }) => {
      return (
        <div className="flex items-center">
          <div className="text-primary-green capitalize">
            {row.original.status === "board_reviewed"
              ? "Board Reviewed"
              : row.original.status}
          </div>
        </div>
      );
    },
  },

  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Board Decision </h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "boardDecision",
    Cell: ({ row }) => {
      return (
        <div className="flex items-center">
          <div
            className={`capitalize break-words ${
              row?.original?.boardDecision === "NA"
                ? "text-primary-gray"
                : "text-primary-green"
            }`}
          >
            {row?.original?.boardDecision === "NA"
              ? "N/A"
              : row?.original?.boardDecision}
          </div>
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Sent To Attorney</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "sentToAssignedAttorney",
    Cell: ({ row }) => {
      return (
        <div className="flex items-center">
          <div
            className={`capitalize ${
              row.original.sentToAssignedAttorney === "yes"
                ? "text-primary-green"
                : "text-primary-gray"
            }`}
          >
            {row.original.sentToAssignedAttorney === "yes"
              ? "Not Sent to Attorney"
              : "N/A"}
          </div>
        </div>
      );
    },
  },
];

export const attorniesColumn = [
  {
    Header: "Attorney Name",
    accessor: "attorney_name",
    Cell: ({ row }) => {
      const navigate = useNavigate();
      return (
        <div
          onClick={() => navigate(`/company/attorny/${row.original._id}/edit`)}
          className="text-primary-teal cursor-pointer"
        >
          {row.original.firstName + " " + row.original.lastName}
        </div>
      );
    },
  },
  {
    Header: () => (
      <div className="flex items-center space-x-1.5">
        <h3>Assigned Comunities</h3>
        <SortingIcon className=" 3xl:w-5 3xl:h-5 cursor-pointer" />
      </div>
    ),
    accessor: "CommunitiesAllowed",
    Cell: ({ row }) => {
      return (
        <div className="cursor-pointer text-primary-teal">
          {row.original.communitiesAllowed?.includes("*")
            ? "All"
            : row.original.communitiesAllowed?.length}
        </div>
      );
    },
  },
  {
    Header: "Email",
    accessor: "email",
  },
];

export const reviewJobColumn = (
  onApprovHandler,
  onRejectHandler,
  reviewJobUpdate,
  onAllClickHandler,
  allIds
) => {
  return [
    {
      Header: () => {
        return (
          <div className="flex items-center gap-2">
            <div className="flex justify-start mt-1">
              <input
                type="radio"
                checked={allIds.every((id) =>
                  reviewJobUpdate.approveJobViolations.includes(id)
                )}
                onClick={() => onAllClickHandler("approv")}
              />
            </div>
            <span>Approve</span>
          </div>
        );
      },
      disableSortBy: true,
      accessor: "approvereviewStatus",
      Cell: ({ row }) => {
        return (
          <div className="text-primary-teal flex items-center">
            <div className="flex justify-start mt-1">
              <input
                type="radio"
                checked={
                  (row.original.reviewStatus === "approved" &&
                    !reviewJobUpdate.rejectJobViolations.includes(
                      row.original._id
                    )) ||
                  reviewJobUpdate.approveJobViolations.includes(
                    row.original._id
                  )
                    ? true
                    : false
                }
                onClick={(e) => {
                  onApprovHandler(e, row.original);
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      Header: () => {
        return (
          <div className="flex items-center gap-2">
            <div className="flex justify-start mt-1">
              <input
                type="radio"
                checked={allIds.every((id) =>
                  reviewJobUpdate.rejectJobViolations.includes(id)
                )}
                onClick={() => onAllClickHandler("reject")}
              />
            </div>
            <span>Remove</span>
          </div>
        );
      },
      disableSortBy: true,
      accessor: "rejectreviewStatus",
      Cell: ({ row }) => {
        return (
          <div className="text-primary-teal flex items-center">
            <div className="flex justify-start mt-1">
              <input
                type="radio"
                checked={
                  (row.original.reviewStatus === "rejected" &&
                    !reviewJobUpdate.approveJobViolations.includes(
                      row.original._id
                    )) ||
                  reviewJobUpdate.rejectJobViolations.includes(row.original._id)
                    ? true
                    : false
                }
                onClick={(e) => {
                  onRejectHandler(e, row.original);
                }}
              />
            </div>
          </div>
        );
      },
    },
    {
      Header: "Violation",
      accessor: "violationContent",
      Cell: ({ row }) => {
        return (
          <div className="text-wrap">
            {row.original.propertyOpenViolations.violationContent}
          </div>
        );
      },
    },
    {
      Header: "Initial Open Date",
      accessor: "openedOn",
      Cell: ({ row }) => {
        return (
          <div className=" flex items-center space-x-4">
            {formatTimestamp(row.original.propertyOpenViolations.openedOn)}
          </div>
        );
      },
    },
  ];
};

export const LetterLevelColumn = (id, getDefaultLetter, community) => {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => {
        const navigate = useNavigate();
        return (
          <div
            className="text-primary-teal cursor-pointer"
            onClick={() => {
              navigate(
                `/community/${id}/letter-level/edit/${row.original._id}`,
                {
                  state: {
                    levelData: row.original,
                  },
                }
              );
            }}
          >
            {row.original.title}
          </div>
        );
      },
    },
    {
      Header: () => (
        <div className="flex items-center space-x-1.5">
          <span>Attorney</span>
          <CustomTooltip
            title="Any levels flagged as 'Attorney' will result in properties escalated to these levels during an inspection being placed into a separate queue for review. Additionally, an email will be sent to the attorney of record for further enforcement."
            placement="right-start"
          >
            <img src={InfoIcon} alt="" className="cursor-pointer" />
          </CustomTooltip>
        </div>
      ),
      disableSortBy: true,
      accessor: "attorney",
      Cell: ({ row }) => {
        return <div>{row.original.flagForAttorney && <FlagIcon />}</div>;
      },
    },
    {
      Header: () => (
        <div className="flex items-center space-x-1.5">
          <span>Set as Default</span>
          <CustomTooltip
            title={`One level must be marked as "default". This is the level that will be selected in the appication by default. Pro tip: We recommend this be your first letter in the violation process (Level 1). This will save you time as you will not need to select a level on any new properties and should you fail to select a level the result would simply be a duplicate Level 1 letter. NOTE: You must create the letter template before marking a level "default".`}
            placement="right-start"
          >
            <img src={InfoIcon} alt="" className="cursor-pointer" />
          </CustomTooltip>
        </div>
      ),
      accessor: "selectDefault",
      Cell: ({ row }) => (
        <div className="py-2 flex items-center">
          <input
            type="checkbox"
            onClick={(e) => {
              getDefaultLetter(row.original._id);
            }}
            checked={
              community.defaultLetter === row.original._id ? true : false
            }
          />
        </div>
      ),
      disableSortBy: true,
    },
  ];
};

export const referenceGuideColumn = [
  {
    Header: "Topic",
    accessor: "topic",
    Cell: ({ row }) => {
      const navigate = useNavigate();
      return (
        <div
          className="text-primary-teal cursor-pointer"
          onClick={() =>
            navigate(
              `/community/${row.original.community}/quick-reference/${row.original._id}/edit`,
              {
                state: {
                  quickRefData: row.original,
                },
              }
            )
          }
        >
          {row.original.topic}
        </div>
      );
    },
  },
  {
    Header: "Details",
    accessor: "details",
    Cell: ({ row }) => {
      return (
        <div className="text-primary-teal font-light">
          {row.original.details}
        </div>
      );
    },
  },
];
