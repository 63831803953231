import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Header from "../../components/Header/Header";
import ViolationCard from "../../components/ViolationCard/ViolationCard";
import axios from "../../utils/helpers/axios";
import { setCommunityId } from "../../redux/features/communitySlice";

function OpenViolation() {
  const { id, pid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [openViolations, setOpenViolations] = useState([]);
  const [community, setCommunity] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getOpenViolations = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/job/openViolations?propertyId=${pid}`
      );

      if (data.success) {
        setOpenViolations(data.violations);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (pid) getOpenViolations();
  }, [pid]);

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  return (
    <div className="w-full">
      <Header
        title="Current Open Violations"
        buttonText={community?.legalName}
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
      />

      <div className=" pb-12 flex-1  px-2 pt-5 space-y-4">
        {isLoading && (
          <div className="flex flex-1 justify-center overflow-auto h-[80%]">
            <ClipLoader color={"#0080A2"} size={50} />
          </div>
        )}

        {!isLoading &&
          openViolations.map((violation) => {
            return <ViolationCard violation={violation} />;
          })}
      </div>
    </div>
  );
}

export default OpenViolation;
