import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import Button from "../Button/Button";
import Header from "../Header/Header";
import Input from "../Input/Input";
import axios from "../../utils/helpers/axios";
import { ToggleSidebarContext } from "../Layout/Layout";
import { setCommunityId } from "../../redux/features/communitySlice";

export default function AddWarnNotesCommunity() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();

  const [warnNote, setWarnNote] = useState({
    title: "",
    note: "",
  });
  const [community, setCommunity] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setWarnNote((prev) => ({ ...prev, [name]: value }));
  };

  const addWarnNote = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.post("/api/community/warnNote", {
        communityId: id,
        title: warnNote.title,
        note: warnNote.note,
      });

      if (data.success) {
        toast.success(data.message);
        // navigate(`/community/${id}/warn-notes`);
        navigate(`/community/dashboard/${id}`);
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  return (
    <div className="w-full">
      <Header title="Add Warn Notes" buttonText={community.legalName} />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4"
      >
        <div className="flex flex-col gap-[1rem]">
          <Input
            label="Title"
            placeholder="Enter Warning Title"
            type="text"
            labelClass="text-primary-teal"
            inputFieldClass="px-4"
            required={true}
            outerClass="w-full "
            extraClass="rounded-full border-gray border-[1px]"
            value={warnNote.title}
            onChange={(e) => onChange(e)}
            name="title"
          />
          <div className="flex flex-col">
            <div className="flex items-center gap-[6px] text-primary-teal font-semibold text-[0.9rem]">
              Notes *
            </div>
            <textarea
              placeholder="Notes"
              style={{
                height: "15rem",
                borderRadius: "12px",
                backgroundColor: "#fff",
                outline: "1px solid #f6f6f6",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                color: "#666666",
                padding: "1rem",
                fontSize: "0.8rem",
                borderColor: "#E2E2E2",
              }}
              onChange={(e) => onChange(e)}
              value={warnNote.note}
              name="note"
            />
          </div>
        </div>

        <div className="flex justify-end gap-[1rem]">
          <Button
            type="submit"
            innerText="Cancel"
            extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white  rounded-full text-[0.8rem] text-primary-red px-4 py-2"
            // onClick={() => navigate(`/community/${id}/warn-notes`)}
            onClick={() => navigate(`/community/dashboard/${id}`)}
          />
          <Button
            type="submit"
            innerText="Submit"
            extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal  rounded-full text-[0.8rem] text-primary-white px-4 py-2"
            onClick={addWarnNote}
            spinnerLight={true}
            isLoading={isLoading}
            disabled={isLoading || !warnNote.title || !warnNote.note}
          />
        </div>
      </div>
    </div>
  );
}
