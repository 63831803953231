import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axiosRawClient from "axios";

import Button from "../Button/Button";
import axios from "../../utils/helpers/axios";
import PhotoPreviewModal from "./PhotoPreviewModel";
import { formatTimestamp } from "../../utils/helpers/dateFormat";

import DeleteIcon from "../../assets/svg/deleteIcon";
import ExpandIcon from "../../assets/svg/expandIcon";
import CompanyDashboardDefault from "../../assets/images/companyDashboard_default.png";
import ImportIcon from "../../assets/svg/importIconGreen";

export default function PhotoViewCard({
  photoHistory,
  download,
  deletePhoto = () => {},
  showDelete = true,
}) {
  const [imageData, setImageData] = useState({});
  const [openModel, setOpenModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const getImage = async () => {
    try {
      const { data } = await axios.get(
        `/api/media/mediaURL?mediaId=${photoHistory.mediaId}`
      );

      if (data.success) {
        console.log(data, "karuu");
        setImageData(data.media);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const downloadImage = async (imageurl) => {
    try {
      setIsLoading(true);

      const response = await axiosRawClient.get(imageurl, {
        responseType: "arraybuffer",
      });

      const mimeType = response.headers["content-type"] || "image/png";
      const blob = new Blob([response.data], { type: mimeType });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = imageData.fileName;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (photoHistory.mediaId) getImage();
  }, [photoHistory.mediaId]);

  return (
    <div className="relative w-[16rem] h-[12rem] border-[1px] border-gray rounded-3xl mr-4 mb-4 flex grow overflow-hidden">
      <div className="absolute top-2 w-full flex gap-2 justify-between px-4">
        <div className="flex items-center w-1/2">
          <span className="bg-primary-white text-primary-teal rounded-3xl px-3 py-2 border-gray border-[0.5px] truncate">
            {imageData?.fileName}
          </span>
        </div>
        <div className="flex gap-2">
          <div className="flex items-center w-fit ">
            {showDelete && (
              <Button
                Icon={DeleteIcon}
                iconClass=" w-[1rem] h-[1rem]"
                extraClass="relative flex justify-center items-center text-primary-teal bg-primary-white py-2 px-2 rounded-full border-gray border-[0.5px]"
                isLoading={isLoadingDelete}
                disabled={isLoadingDelete}
                onClick={() => {
                  deletePhoto(photoHistory?._id, setIsLoadingDelete);
                }}
              />
            )}
          </div>
          <div className="flex items-center w-fit ">
            <Button
              Icon={ExpandIcon}
              iconClass=" w-[1rem] h-[1rem]"
              extraClass="relative flex justify-center items-center text-primary-teal bg-primary-white py-2 px-2 rounded-full border-gray border-[0.5px]"
              onClick={() => setOpenModel(true)}
            />
          </div>
        </div>
      </div>
      {download && (
        <div className="flex justify-between absolute bottom-2 w-full px-4">
          <div className="bg-primary-white text-primary-teal rounded-3xl px-3 py-2 border-gray border-[0.5px] text-xs">
            {imageData?.createdAt && formatTimestamp(imageData?.createdAt)}
          </div>
          <div className="absolute bottom-0 right-4 flex items-center w-fit">
            <Button
              Icon={ImportIcon}
              iconClass=" w-[18px] h-[18px]"
              extraClass="text-primary-teal bg-primary-white rounded-full p-[0.5rem] border-gray border-[0.5px]"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={() => downloadImage(imageData.s3PresignedURL)}
            />
          </div>
        </div>
      )}
      <img
        src={imageData.s3PresignedURL || CompanyDashboardDefault}
        className="h-full w-full object-fit"
        alt="No Data Found"
      />
      {openModel && (
        <PhotoPreviewModal
          {...{
            openModel,
            setOpenModel,
            imageData,
            deletePhoto,
            photoHistory,
            showDelete,
          }}
        />
      )}
    </div>
  );
}
