import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";

import { ToggleSidebarContext } from "../../components/Layout/Layout";
import Button from "../../components/Button/Button";
import Indicator from "../../components/Indicator/Indicator";
import Header from "../../components/Header/Header";
import Input from "../../components/Input/Input";
import axios from "../../utils/helpers/axios";
import PropertyDropDown from "../../components/PropertyDropdown/PropertyDropdown";
import { setCommunityId } from "../../redux/features/communitySlice";
import PhotoHeader from "../../components/Header/PhotoHeader";
import WarnNoteCard from "../../components/WarnNoteCard/WarnNoteCard";
import { formatTimestamp } from "../../utils/helpers/dateFormat";
import SwitchInputv2 from "../../components/Input/SwitchInputv2";

import PropertiesIcon from "../../assets/svg/propertiesIcon";
import SearchIcon from "../../assets/svg/searchIcon";
import UserIcon from "../../assets/svg/userIcon";
import ViolationIcon from "../../assets/svg/violationIcon";
import CommunitiesIcon from "../../assets/svg/communitiesIcon";
import JobIcon from "../../assets/svg/jobIcon";
import CompanyDashboardDefault from "../../assets/images/companyDashboard_default.png";
import DocumnetLibraryIcon from "../../assets/svg/documentLibraryIcon";
import AddIcon from "../../assets/svg/addIcon";

export default function CommunitiesDashboard() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();

  const [communitiesAllowed, setCommunitiesAllowed] = useState([]);
  const [community, setCommunity] = useState({});
  const [filePreview, setFilePreview] = useState("");
  const [properties, setProperties] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [warnNotes, setWarnNotes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [statsData, setStatsData] = useState({});
  const [filterFields, setFilterFields] = useState({
    field: "status",
    fieldValue: "active",
  });
  const [isLoadingProperty, setIsLoadingProperty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingArchive, setIsLoadingArchive] = useState(false);
  const [isLoadingWarnNotes, setIsLoadingWarnNotes] = useState(false);
  const [isLoadingLogo, setIsLoadingLogo] = useState(false);
  const [isLoadingStats, setIsLoadingStats] = useState(false);

  const getStats = async () => {
    try {
      setIsLoadingStats(true);
      const { data } = await axios.get(
        `/api/community/stats?communityId=${id}`
      );

      if (data.success) {
        setStatsData(data?.communityStats);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingStats(false);
    }
  };

  const getCommunityData = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getMediaURL = async () => {
    try {
      setIsLoadingLogo(true);

      const { data } = await axios.get(
        `/api/media/mediaURL?mediaId=${community?.coverPicture}`
      );

      if (data.success) {
        setFilePreview(data.media.s3PresignedURL);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingLogo(false);
    }
  };

  const getProperties = async () => {
    try {
      setIsLoadingProperty(true);

      const { data } = await axios.get(
        `/api/property/?communityId=${id}&pageNum=1&rowsPerPage=5&sortFields=updatedAt&sortOrders=-1&searchQuery=${searchQuery}`
      );

      if (data.success) {
        setProperties(data.properties);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingProperty(false);
    }
  };

  const getWarnNotes = async () => {
    try {
      setIsLoadingWarnNotes(true);

      const { data } = await axios.get(
        `/api/community/warnNote?communityId=${id}&pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=updatedAt&sortOrders=-1&filterFields=status&filterValues=${filterFields.fieldValue}`
      );

      if (data.success) {
        setWarnNotes(data.warnNotes);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingWarnNotes(false);
    }
  };

  const deleteWarnNoteProperty = async (wid) => {
    try {
      setIsLoadingDelete(true);

      const { data } = await axios.delete(
        `/api/community/warnNote?communityId=${id}&warnNoteIds=${wid}`
      );

      if (data.success) {
        toast.success(data.message);
        getWarnNotes();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const updateWarnNoteStatus = async (wid) => {
    try {
      setIsLoadingArchive(true);

      const { data } = await axios.patch("/api/community/warnNote", {
        warnNoteId: wid,
        communityId: id,
        updateFields: {
          status: filterFields.fieldValue === "active" ? "archive" : "active",
        },
      });

      if (data.success) {
        toast.success(data.message);
        getWarnNotes();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingArchive(false);
    }
  };

  const getAllCommunities = async () => {
    try {
      const { data } = await axios.get(
        `/api/community?sortFields=legalName&sortOrders=1&rowsPerPage=1000`
      );

      if (data.success) {
        setCommunitiesAllowed(data?.communities);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllowedCommunities = async () => {
    try {
      const { data } = await axios.get("/api/auth/profile");

      if (data.success) {
        const user = data?.userProfile[0];

        if (user?.communitiesAllowed.includes("*")) getAllCommunities();
        else setCommunitiesAllowed(user?.communitiesAllowedDetails);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) getWarnNotes();
  }, [id, currentPage, rowsPerPage, filterFields.fieldValue]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    if (searchQuery.length > 2) getProperties();
  }, [searchQuery]);

  useEffect(() => {
    if (id) {
      getCommunityData();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  useEffect(() => {
    if (community.coverPicture) getMediaURL();
    else setFilePreview(CompanyDashboardDefault);
  }, [community.coverPicture]);

  useEffect(() => {
    getAllowedCommunities();
  }, []);

  useEffect(() => {
    getStats();
  }, []);

  return (
    <div className="w-full">
      <Header
        title="Dashboard"
        buttonText={community?.legalName}
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
        dropdown={true}
        communitiesAllowed={communitiesAllowed}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-4 h-[92%]"
      >
        <div className={"relative mt-4"}>
          <Input
            iconClass="absolute left-4 text-secondary-gray"
            Icon={SearchIcon}
            placeholder="Search by Address / Street / Owner’s Name"
            inputFieldClass="placeholder:text-primary-gray-light"
            extraClass="border-gray border-[1px] shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full px-12"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
          <PropertyDropDown
            properties={properties}
            isLoading={isLoadingProperty}
            searchQuery={searchQuery}
          />
        </div>

        <div className="relative w-full h-[17rem] border-[1px] border-gray shadow-inputShadow hover:shadow-inputShadowActive rounded-2xl grow">
          {isLoadingLogo && (
            <div className="absolute left-[50%] pt-8">
              <ClipLoader color={"#0080A2"} size={50} />
            </div>
          )}

          <img
            src={filePreview || CompanyDashboardDefault}
            alt=""
            className="w-full rounded-xl h-full object-fit"
          />

          <Button
            innerText={community?.legalName}
            extraClass={`absolute bottom-5 left-5 shadow-button bg-primary-white w-fit px-4  rounded-full py-1 text-[0.9rem] text-primary-teal`}
          />
        </div>

        <div className="flex flex-col gap-[1rem]">
          <div className="flex gap-[2rem]">
            <Indicator
              indicatorText="Properties"
              value={statsData?.propeties || "-"}
              Icon={PropertiesIcon}
            />
            <Indicator
              indicatorText="Users"
              value={statsData?.users || "-"}
              Icon={UserIcon}
            />
            <Indicator
              indicatorText="Open Violations"
              value={statsData?.openViolations || "-"}
              Icon={ViolationIcon}
              extraClass={"border-r-[2.2px] border-primary-teal"}
            />
          </div>
          <div className="flex gap-[2rem]">
            <Button
              Icon={JobIcon}
              iconClass=" w-[15px] h-[15px]"
              extraClass="relative text-primary-white bg-primary-teal py-2 w-full px-4 text-sm rounded-full "
              innerText="Jobs"
              onClick={() => navigate(`/community/jobs/${id}`)}
            />

            <Button
              Icon={PropertiesIcon}
              iconClass=" w-[15px] h-[15px]"
              extraClass="relative text-primary-white bg-primary-teal py-2 w-full px-4 py-[.5rem] text-sm rounded-full "
              innerText="Properties"
              onClick={() => navigate(`/community/${id}/properties`)}
            />

            <Button
              Icon={CommunitiesIcon}
              iconClass=" w-[15px] h-[15px]"
              extraClass="relative text-primary-white bg-primary-teal py-2 w-full px-4 py-[.5rem] text-sm rounded-full "
              innerText=" Community Inspection"
              onClick={() => {
                window.open("https://inspector.procamtasks.com/", "_blank");
              }}
            />
          </div>
          <div className="flex flex-col h-[50rem]">
            <div className="flex justify-between items-center border-b-2 border-primary-teal my-[1rem] ">
              <PhotoHeader Icon={DocumnetLibraryIcon} title="Warn Notes" />
              <div className="flex gap-[0.4rem]">
                <div className="flex items-center px-2">
                  <SwitchInputv2
                    isChecked={
                      filterFields.fieldValue === "archive" ? true : false
                    }
                    onChange={(id, value) => {
                      if (value === "add")
                        setFilterFields({
                          field: "status",
                          fieldValue: "archive",
                        });

                      if (value === "remove")
                        setFilterFields({
                          field: "status",
                          fieldValue: "active",
                        });
                    }}
                  />
                  <div className="text-primary-gray">Show Archive</div>
                </div>
                <Button
                  innerText="Add New Warning Note"
                  Icon={AddIcon}
                  iconClass="h-[1rem]"
                  extraClass={
                    "shadow-button bg-primary-teal w-fit px-4 border-[1px] border-primary-teal  rounded-full text-[0.9rem] text-primary-white py-2"
                  }
                  onClick={() => navigate(`/community/${id}/warn-notes/add`)}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1 gap-[0.6rem] overflow-auto pr-2 pb-4 mb-[6rem]">
              {isLoadingWarnNotes && (
                <div className="flex flex-1 justify-center">
                  <ClipLoader color={"#0080A2"} size={50} />
                </div>
              )}
              {!isLoadingWarnNotes && (
                <>
                  {warnNotes.map((warnNote) => {
                    return (
                      <WarnNoteCard
                        note={warnNote.title}
                        description={warnNote.note}
                        date={formatTimestamp(warnNote.createdAt)}
                        wid={warnNote._id}
                        onClickHandler={deleteWarnNoteProperty}
                        onClickStatusHandler={updateWarnNoteStatus}
                        status={warnNote.status}
                        isLoadingArchive={isLoadingArchive}
                        isLoadingDelete={isLoadingDelete}
                      />
                    );
                  })}
                </>
              )}

              {!isLoadingWarnNotes && warnNotes.length === 0 && (
                <div className="text-primary-gray flex justify-center">
                  No Warn Notes Found
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
