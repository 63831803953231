import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import {
  Select as MUISelect,
  MenuItem,
  ListItemText,
  Checkbox,
  OutlinedInput,
  Autocomplete,
  TextField,
} from "@mui/material";
import { ClipLoader } from "react-spinners";
import Modal from "react-responsive-modal";

import Header from "../Header/Header";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Select from "../Input/SelectInput";
import axios from "../../utils/helpers/adminAxios";
import CustomTooltip from "../Tooltip/Tooltip";
import { ToggleSidebarContext } from "../Layout/Layout";
import capitalizeInitials from "../../utils/helpers/capitalizeInitials";
import { OAUTH_FALLBACK_URL } from "../../utils/helpers/url";
import SwitchInput from "../Input/SwitchInput";

import InfoIcon from "../../assets/svg/infoIcon.svg";
import CompanyDashboardDefault from "../../assets/images/companyDashboard_default.png";
import CropEasy from "../Crop/CropEasy";

export default function AddUserCompany() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    communitiesAllowed: [],
    avatar: "",
    companyId: "",
    companyName: "",
    active: true,
  });

  const [file, setFile] = useState("");
  const [filePreview, setFilePreview] = useState("");
  const [cropImagePreview, setCropImagePreview] = useState("");
  const [communities, setCommunities] = useState([]);
  const [roleDropdown, setRoleDropdown] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [companyDropdown, setCompanyDropdown] = useState([]);
  const [selectedCommunities, setSelectedCommunities] = useState([]);
  const [password, setPassword] = useState("");
  const [openCrop, setOpenCrop] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "15rem",
        borderRadius: "1rem",
        marginTop: "0.5rem",
      },
    },
  };

  const filteredCompanyDropdown = companyDropdown.filter((company) =>
    company.companyName
      .toLowerCase()
      .includes(userData.companyName.toLowerCase())
  );

  function handleChange(e) {
    if (e.target.files[0]) {
      setFilePreview(URL.createObjectURL(e.target.files[0]));
      setOpenCrop(true);
    }
  }

  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    setUserData((prev) => ({ ...prev, [name]: value }));
  };

  const uploadLogo = async () => {
    try {
      setIsLoadingUpdate(true);

      const { data } = await axios.post("/admin/uploadURL/image", {
        fileName: file.name,
        mimeType: file.type,
      });

      if (data.success) {
        put(data.s3PostPayload, data.mediaId);
      }
    } catch (error) {
      console.error(error);
      setIsLoadingUpdate(false);
    }
  };

  function put(url, media_id) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "image/png");

    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then(() => ackUploadMedia(media_id))
      .catch((error) => {
        console.error(error);
        setIsLoadingUpdate(false);
      });
  }

  const ackUploadMedia = async (media_id) => {
    try {
      const { data } = await axios.post(`/admin/ackMediaUpload`, {
        mediaId: media_id,
        company: userData.companyId,
      });

      if (data.success) {
        toast.success("Avatar uploaded successfully.");
        setUserData((prev) => ({ ...prev, avatar: data.media._id }));
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
      setIsLoadingUpdate(false);
    }
  };

  const getCommunities = async () => {
    try {
      const { data } = await axios.get(
        `/admin/getCommunities/?companyId=${userData.companyId}&sortFields=legalName&sortOrders=1`
      );

      if (data.success) {
        setCommunities(data.communities);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    }
  };

  const getCompanyRoles = async () => {
    try {
      const { data } = await axios.get(
        `/admin/getCompanyRoles?companyId=${userData.companyId}`
      );

      if (data.success) {
        const updatedRoles = data.roles.map((item) => ({
          value: item.role,
        }));

        const updatedData = [
          ...updatedRoles,
          {
            value: "company_admin",
          },
          {
            value: "board_user",
          },
        ];

        updatedData.sort((a, b) => a.value.localeCompare(b.value));

        const uniqueRoles = [...new Set(updatedData.map((item) => item.value))];

        const result = uniqueRoles.map((value) => {
          return {
            role:
              value === "company_admin"
                ? "Company Administrator"
                : capitalizeInitials(value, "_"),
            value,
          };
        });
        setRoleDropdown(result);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    }
  };

  const getUserById = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/admin/getUsers?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        const user = data.users[0];

        setUserData(
          Object.keys(userData).reduce((acc, key) => {
            if (key === "companyId") acc[key] = user.company;
            else if (key in user) acc[key] = user[key];

            return acc;
          }, {})
        );

        setSelectedCommunities(user.communitiesAllowed);

        getMediaURL(user.avatar);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUserFromEmail = async (payload) => {
    try {
      const { data } = await axios.get(
        `/admin/getUsers?sortFields=updatedAt&sortOrders=-1&filterFields=email&filterValues=${payload.email}`
      );

      if (data.success) {
        if (data.matchedUserCount !== 0)
          toast.error("User already exist with this Email.");
        else
          navigate(`/security-permission/${payload.email}?masterUser=true`, {
            state: {
              userData: payload,
            },
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMediaURL = async (media_id) => {
    if (!media_id) return;

    const { data } = await axios.get(`/admin/mediaURL?mediaId=${media_id}`, {
      mediaId: media_id,
    });

    if (data.success) {
      setCropImagePreview(data.media.s3PresignedURL);
    }
  };

  const getCompanyDropdown = async () => {
    const { data } = await axios.get(
      `/admin/getCompanies?rowsPerPage=2000&sortFields=companyName&sortOrders=1&filterFields=active&filterValues=true`
    );
    if (data.success) {
      setCompanyDropdown(data.companies);
    }
  };

  const addUser = async () => {
    try {
      setIsLoadingUpdate(true);

      const payload = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        role: userData.role,
        companyId: userData.companyId,
      };

      if (userData.avatar) payload.avatar = userData.avatar;
      if (selectedCommunities.length)
        payload.communitiesAllowed = selectedCommunities;

      if (userData.role !== "company_admin") {
        return getUserFromEmail(payload);
      }

      const { data } = await axios.post("/admin/oboardUser", payload);

      if (data.success) {
        toast.success(data.message || "User added successfully.");
        navigate("/user");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const updateUser = async () => {
    try {
      setIsLoadingUpdate(true);
      const payload = {
        userId: id,
        ...Object.keys(userData).reduce((acc, key) => {
          if (key !== "companyName" && key !== "communitiesAllowed")
            acc[key] = userData[key];

          return acc;
        }, {}),
        communitiesAllowed: selectedCommunities,
      };

      if (password) payload.password = password;

      const { data } = await axios.patch("/admin/updateUser", payload);

      if (data.success) {
        toast.success("User updated successfully.");
        navigate("/user");
      }
    } catch (error) {
      console.error();
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const deleteUserAPI = async (email) => {
    setIsLoadingDelete(true);
    try {
      const response = await axios.delete(`/admin/deleteUser?email=${email}`);

      if (response?.data?.success) {
        toast.success("User Deleted Successfully");
        setIsLoading(false);
        navigate("/user");
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const sendResetPassword = async () => {
    const url = `${OAUTH_FALLBACK_URL}/user`;

    try {
      const { data } = await axios.post(`/api/auth/forgotPassword`, {
        email: userData.email,
        fallBackURL: url,
      });

      if (data.success)
        toast.success(
          "User will receive a link to the registered email address."
        );
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (userData.avatar && !id) addUser();
    if (file && id) updateUser();
  }, [userData.avatar]);

  useEffect(() => {
    getCompanyDropdown();
  }, []);

  useEffect(() => {
    if (id) getUserById();
  }, [id]);

  useEffect(() => {
    if (userData.companyId) {
      getCompanyRoles();
      getCommunities();
    }
  }, [userData]);

  return (
    <div className="w-full">
      {openCrop && (
        <CropEasy
          {...{
            photoURL: filePreview,
            setPhotoURL: setCropImagePreview,
            openCrop: openCrop,
            setOpenCrop: setOpenCrop,
            setFile: setFile,
            aspect: 1,
          }}
        />
      )}
      <Header title={id ? "Edit User" : "Add User"} />
      {isLoading && (
        <div className="flex justify-center items-center w-full mt-2">
          <ClipLoader color={"#0080A2"} size={50} />
        </div>
      )}
      {!isLoading && (
        <div
          onClick={() => setToggleSidebar(false)}
          className="w-full h-[92%] flex flex-col px-8 pt-4 gap-4 overflow-auto scrollbar"
        >
          <div className="flex gap-4">
            <Input
              label="First Name"
              labelClass="text-primary-teal"
              type="text"
              placeholder="First Name"
              name={"firstName"}
              required={true}
              value={userData.firstName}
              onChange={onChangeHandler}
              inputFieldClass="px-4"
              outerClass="w-full"
              extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
            />
            <Input
              label="Last Name"
              labelClass="text-primary-teal"
              type="text"
              required={true}
              placeholder="Last Name"
              name={"lastName"}
              value={userData.lastName}
              onChange={onChangeHandler}
              inputFieldClass="px-4"
              outerClass="w-full"
              extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
            />
          </div>

          <div className="flex gap-4 ">
            <Input
              label="Email Address"
              labelClass="text-primary-teal"
              type="text"
              name={"email"}
              value={userData.email}
              onChange={onChangeHandler}
              required={true}
              placeholder="Enter Email Address"
              inputFieldClass="px-4"
              outerClass="w-1/2"
              extraClass="border-gray border-[1px] shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
            />
            {id && (
              <Input
                label="Password"
                labelClass="text-primary-teal"
                type="text"
                required={true}
                placeholder="Enter Password"
                inputFieldClass="px-4"
                outerClass="w-1/2"
                extraClass="border-gray border-[1px]  shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
                onChange={(e) => setPassword(e.target.value)}
                info={`You may create a new password for this user or click "Send Reset Password Link" to allow the user to create a new password.`}
                customText={"Send Reset Password Link"}
                customTextOnClick={sendResetPassword}
                customTextClass={"underline"}
              />
            )}
          </div>

          <div className=" flex gap-4">
            <Select
              label="Company"
              labelClass="text-primary-teal"
              type="text"
              required={true}
              id="company"
              value={userData.companyName}
              handleChange={(id, data) =>
                setUserData((prev) => ({
                  ...prev,
                  companyId: data._id,
                  companyName: data.companyName,
                }))
              }
              onHandleInputChange={(e) => {
                setUserData((prev) => ({
                  ...prev,
                  companyName: e.target.value,
                }));
              }}
              fieldName="companyName"
              selector="companyName"
              dropdownData={filteredCompanyDropdown}
              placeholder="Select Company"
              inputFieldClass="px-4 rounded-full"
              outerClass="w-1/2"
              extraClass="border-gray border-[1px] shadow-inputShadow hover:shadow-inputShadowActive focus:shadow-inputShadowActive rounded-full"
              disabled={id}
            />
            {userData.companyId && (
              <div className="flex flex-col w-1/2">
                <div className="w-full">
                  <div className="flex gap-1">
                    <span className={"text-primary-teal text-sm font-semibold"}>
                      Role *
                    </span>
                    <CustomTooltip
                      title={`Note that assigning a role does not set a user's permissions except in the cases of Company Administrator and Board User, which are default system roles whose permissions cannot be modified. All other roles are for internal organization only.
This list is populated with roles that have been created from the company level. Those with the appropriate permissions can add additional roles from the "Company Roles" tab in Settings.`}
                      placement={"right"}
                    >
                      <img src={InfoIcon} alt="" className="cursor-pointer" />
                    </CustomTooltip>
                  </div>
                  <Autocomplete
                    value={
                      userData.role === "company_admin"
                        ? "Company Administrator"
                        : capitalizeInitials(userData.role, "_")
                    }
                    onChange={(event, newValue) => {
                      setUserData((prev) => ({
                        ...prev,
                        role: roleDropdown.find(
                          (roleObj) => roleObj.role === newValue
                        )?.value,
                      }));
                    }}
                    inputValue={selectedRole}
                    onInputChange={(e, newInputValue) => {
                      setSelectedRole(newInputValue);
                    }}
                    options={roleDropdown.map((role) => role?.role)}
                    sx={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField placeholder="Select Role" {...params} />
                    )}
                    size="small"
                  />
                </div>
                {userData.role === "company_admin" && (
                  <span className="font-normal text-xs text-secondary-gray">
                    By selecting the role Company Administrator this user will
                    have full security permissions to the company by default. If
                    you wish to set custom permissions, please select a
                    different role, which will allow custom permissions to be
                    set.
                  </span>
                )}
              </div>
            )}
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex gap-4">
              <Input
                label="Profile Picture"
                labelClass="text-primary-teal"
                type="file"
                inputFieldClass="px-4 placeholder-red-300 file:border-none file:bg-transparent file:text-primary-teal file:cursor-pointer "
                outerClass="w-1/2"
                extraClass="border-gray border-[1px]  shadow-inputShadow rounded-full"
                onChange={handleChange}
              />
              {userData.role && userData.role !== "company_admin" && (
                <div className="w-1/2">
                  <div className="flex gap-1">
                    <span className={"text-primary-teal text-sm font-semibold"}>
                      Communities *
                    </span>
                    <CustomTooltip title={"info"} placement={"right"}>
                      <img src={InfoIcon} alt="" className="cursor-pointer" />
                    </CustomTooltip>
                  </div>
                  <MUISelect
                    sx={{
                      width: "100%",
                      borderRadius: "100rem",
                      fontFamily: "inherit",
                      fontSize: "14px",
                      height: "2rem",
                    }}
                    size="small"
                    id="demo-multiple-checkbox"
                    multiple
                    MenuProps={MenuProps}
                    value={selectedCommunities}
                    onChange={(e) => {
                      if (e.target.value.includes("*"))
                        return setSelectedCommunities(["*"]);

                      setSelectedCommunities(e.target.value);
                    }}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.includes("*")) return "All";

                      return selected
                        .map(
                          (cummunityID) =>
                            communities.find(
                              (community) => community._id === cummunityID
                            )?.legalName
                        )
                        .join(", ");
                    }}
                  >
                    <MenuItem key={"all communities"} value={"*"}>
                      <Checkbox checked={selectedCommunities.includes("*")} />
                      <ListItemText primary="All" />
                    </MenuItem>
                    {communities.map((community) => (
                      <MenuItem
                        key={community._id}
                        value={community._id}
                        disabled={selectedCommunities.includes("*")}
                      >
                        <Checkbox
                          checked={
                            selectedCommunities.findIndex(
                              (item) => item === community._id
                            ) > -1
                          }
                        />
                        <ListItemText primary={community.legalName} />
                      </MenuItem>
                    ))}
                  </MUISelect>
                  {selectedCommunities.length === 0 && (
                    <p className="text-primary-red text-[0.7rem]">
                      * Select atleast one Community
                    </p>
                  )}
                </div>
              )}
            </div>
            <div className="space-y-2">
              <div className="text-secondary-gray-light">Preview Image</div>
              <div className=" h-[8rem] w-[8rem] border-[1px] border-gray rounded-xl shadow-inputShadow">
                <img
                  src={cropImagePreview || CompanyDashboardDefault}
                  className="w-full rounded-xl h-full object-fit"
                  alt=""
                />
              </div>
            </div>
          </div>

          {id && (
            <div className="w-full h-full">
              <SwitchInput
                labelClass="text-primary-green 3xl:text-lg"
                label="Active"
                description="User Status"
                id="active"
                handleChange={(id, isChecked) =>
                  setUserData((prev) => ({
                    ...prev,
                    active: isChecked ? "true" : "false",
                  }))
                }
                value={userData.active === "false" ? false : true}
              />
            </div>
          )}

          <div className="flex mt-auto justify-between pb-4">
            <div>
              {id && (
                <>
                  <div className=" p-x-4">
                    <div className="flex gap-x-4">
                      <Button
                        onClick={() => {
                          setOpen(true);
                        }}
                        innerText="Delete User"
                        extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full text-sm uppercase text-primary-red py-2 px-4"
                      />
                      <Button
                        onClick={() => {
                          navigate(`/security-permission/${id}?edit=true`);
                        }}
                        innerText="Edit Security Permissions"
                        extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal w-fit rounded-full py-2  px-4 text-sm  text-primary-white"
                      />
                    </div>
                  </div>
                  <Modal
                    closeOnOverlayClick={true}
                    showCloseIcon={false}
                    blockScroll={true}
                    open={open}
                    onClose={() => {
                      setOpen(false);
                    }}
                    center
                  >
                    <div className="shadow-inputShadow rounded-xl p-4 flex flex-col">
                      <div className="text-primary-teal text-lg">
                        Are you sure you wish to delete this User?
                      </div>
                      <div className="flex justify-end w-full text-sm gap-4">
                        <Button
                          onClick={() => setOpen(false)}
                          innerText="Cancel"
                          extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-white rounded-full py-2 px-4 text-primary-teal"
                        />
                        <Button
                          onClick={() => {
                            deleteUserAPI(userData?.email);
                          }}
                          isLoading={isLoadingDelete}
                          disabled={isLoadingDelete}
                          innerText="Delete User"
                          extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full py-2 px-4 text-primary-red"
                        />
                      </div>
                    </div>
                  </Modal>
                </>
              )}
            </div>
            <div className="flex space-x-4 mt-auto justify-end">
              <Button
                innerText="Cancel"
                onClick={() => {
                  navigate("/user");
                }}
                extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full py-2 px-4 text-sm uppercase text-primary-red"
              />
              {id ? (
                <Button
                  type="submit"
                  innerText={"Save Changes"}
                  onClick={() => {
                    file ? uploadLogo() : updateUser();
                  }}
                  isLoading={isLoadingUpdate}
                  spinnerLight={true}
                  disabled={
                    isLoadingUpdate ||
                    Object.entries(userData).some(([key, value]) => {
                      if (key === "avatar") return false;
                      if (key === "communitiesAllowed") return false;
                      return value === "";
                    }) ||
                    (selectedCommunities.length === 0 &&
                      userData.role !== "company_admin")
                  }
                  extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-sm text-primary-white"
                />
              ) : (
                <Button
                  type="submit"
                  innerText={"Add User Permissions and Submit"}
                  onClick={() => {
                    filePreview ? uploadLogo() : addUser();
                  }}
                  isLoading={isLoadingUpdate}
                  spinnerLight={true}
                  disabled={
                    isLoadingUpdate ||
                    Object.entries(userData).some(([key, value]) => {
                      if (key === "avatar") return false;
                      if (key === "communitiesAllowed") return false;
                      return value === "";
                    }) ||
                    (selectedCommunities.length === 0 &&
                      userData.role !== "company_admin")
                  }
                  extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-sm text-primary-white"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
