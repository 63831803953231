import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

import Button from "../../components/Button/Button";
import { LetterLevelColumn } from "../../constants/TableColumns/Columns";
import CommunityViolationTable from "../../components/Table/CommunityViolationTable";
import Header from "../../components/Header/Header";
import axios from "../../utils/helpers/axios";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import { setCommunityId } from "../../redux/features/communitySlice";
import SwitchInputv2 from "../../components/Input/SwitchInputv2";

import AddIcon from "../../assets/svg/addIcon";

export default function LetterLevels() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const dispatch = useDispatch();

  const [community, setCommunity] = useState({});
  const [letterLevels, setLetterLevels] = useState([]);
  const [archieved, setArchieved] = useState("no");
  const [isLoading, setIsLoading] = useState(false);

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDefaultLetter = async (levelId) => {
    try {
      setIsLoading(true);
      const { data } = await axios.patch(
        `/api/community/letterTemplate/default`,
        {
          communityId: id,
          letterTemplateId: levelId,
        }
      );

      if (data.success) {
        toast.success(data.message);
        getCommunity();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getLetterLevels = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/community/letterLevel?communityId=${id}&archieved=${archieved}`
      );

      if (data.success) {
        setLetterLevels(data.letterLevels);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCommunity();
      getLetterLevels();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  useEffect(() => {
    getLetterLevels();
  }, [archieved]);

  return (
    <div className="w-full">
      <Header
        title="Letter Levels"
        buttonText={community?.legalName}
        onClick={() => {
          navigate(`/community/dashboard/${id}`);
        }}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="relative w-full h-[92%] flex grow flex-col px-[2.4rem] "
      >
        <div className="flex justify-between">
          <div className="flex flex-col py-[1rem]">
            <div className="text-secondary-gray text-[1rem]">
              Creating a Level will add a new Letter Selector with the same name
              to the application.
            </div>
            <div className="text-primary-teal text-[0.9rem]">
              Try limiting your levels to 4 or 5, and keep the names short as
              that is how it will appear in the application.
            </div>
          </div>
          <div className="flex gap-[1.2rem]">
            <div className="flex items-center px-2">
              <SwitchInputv2
                isChecked={archieved === "yes" ? true : false}
                onChange={(id, value) => {
                  if (value === "add") setArchieved("yes");
                  if (value === "remove") setArchieved("no");
                }}
              />
              <div className="text-primary-gray">Show Archive</div>
            </div>

            <Button
              Icon={AddIcon}
              iconClass="w-[15px] h-[15px]"
              extraClass="relative h-fit text-primary-white bg-primary-teal py-2 mt-4 px-4 py-[.5rem] text-sm rounded-full"
              innerText="Add New Level"
              onClick={() => {
                navigate(`/community/${id}/letter-level/add`);
              }}
            />
          </div>
        </div>

        <div className="overflow-auto scrollbar grow px-2 min-h-[6rem] flex justify-center">
          {isLoading && <ClipLoader color={"#0080A2"} size={50} />}
          {!isLoading && (
            <CommunityViolationTable
              pageSize={letterLevels.length}
              COLUMNS={LetterLevelColumn(id, getDefaultLetter, community)}
              tableData={letterLevels}
            />
          )}
        </div>
      </div>
    </div>
  );
}
