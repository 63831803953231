import { useContext, useRef, useState } from "react";

import Header from "../Header/Header";
import { ToggleSidebarContext } from "../Layout/Layout";
import Input from "../Input/Input";
import Select from "../Input/SelectInput";
import Button from "../Button/Button";
import CKEditorComponent from "../CKEditor/CKEditor";
import BasicTooltip from "../Tooltip/EditorTooltip";
import MarginModel from "../MarginModel/MarginModel";

import MarginIcon from "../../assets/svg/marginIcon.svg";
import UploadFromDekstopIcon from "../../assets/svg/uploadFromDekstopIcon.svg";

function AdminAddHelpArticle() {
  const imageUploadRef = useRef("");
  const { setToggleSidebar } = useContext(ToggleSidebarContext);

  const [margins, setMargins] = useState({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  });
  const [filePreview, setFilePreview] = useState("");
  const [value, setValue] = useState("");
  const [showMarginModel, setShowMarginModel] = useState(false);

  return (
    <div className="w-full">
      <Header title="Add Article" buttonText={"Master Admin"} />
      <div
        onClick={() => setToggleSidebar(false)}
        className="w-full h-[92%] flex flex-col px-8 py-4 gap-4"
      >
        <div className="w-full flex gap-4 items-center">
          <Input
            label="Create New Section"
            labelClass="text-primary-teal"
            placeholder="Enter Section Name"
            inputFieldClass="placeholder:text-primary-gray"
            onChange={(e) => {}}
            extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-6"
            outerClass={"w-full"}
          />
          <span className="text-secondary-gray-light pt-4 text-[0.9rem]">
            OR
          </span>
          <Select
            label="Choose Existing Section"
            labelClass="text-primary-teal"
            iconClass="absolute top-[22%] left-2 text-secondary-gray"
            placeholder="Getting Started"
            inputFieldClass="placeholder:text-primary-gray"
            dropdownData={[]}
            id="company"
            fieldName="companyName"
            value={() => {}}
            handleChange={() => {}}
            extraClass="relative w-full border-gray border-[1px] py-[0.1rem] rounded-full px-8"
            onHandleInputChange={(e) => {}}
            outerClass={"w-full"}
          />
        </div>

        <h2 className="text-primary-teal text-[1rem]">Enter Article Name</h2>

        <div className="overflow-auto  relative grow pb-4">
          <div
            className="absolute right-0 p-[0.3rem]"
            style={{ zIndex: "100" }}
          >
            <div className="flex items-center">
              <BasicTooltip
                Icon={
                  <img
                    src={UploadFromDekstopIcon}
                    alt=""
                    onClick={() => {
                      imageUploadRef.current.click();
                    }}
                    className={`"cursor-pointer"`}
                  />
                }
                title="Upload image from computer"
              />
              <BasicTooltip
                Icon={
                  <img
                    src={MarginIcon}
                    alt=""
                    onClick={() => setShowMarginModel(!showMarginModel)}
                    className="cursor-pointer"
                  />
                }
                title="Adjust Margin"
              />
            </div>

            {showMarginModel && (
              <MarginModel
                margins={margins}
                // onChange={handleInputChange}
                setShowMarginModel={setShowMarginModel}
                setMargins={setMargins}
                // letterTemplate={letterTemplate}
                // edit={id}
              />
            )}
          </div>
          <CKEditorComponent
            setValue={setValue}
            value={value}
            filePreview={filePreview}
          />
        </div>

        <div className="flex gap-x-2 justify-end -mb-[1.5rem]">
          <Button
            type="submit"
            innerText="Cancel"
            extraClass="shadow-button bg-primary-whitepx-4 border-[1px] border-primary-red rounded-full py-2 px-4 text-sm captilize text-primary-red"
            onClick={() => {}}
          />
          <Button
            innerText={`Submit`}
            extraClass="shadow-button bg-primary-teal rounded-full text-sm text-primary-white py-2 px-4 min-w-fit"
          />
        </div>
      </div>
    </div>
  );
}

export default AdminAddHelpArticle;
