import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import toast from "react-hot-toast";

import Button from "../../components/Button/Button";
import Header from "../../components/Header/Header";
import WarnNoteCard from "../../components/WarnNoteCard/WarnNoteCard";
import axios from "../../utils/helpers/axios";
import Pagination from "../../components/Pagination/Pagination";
import { formatTimestamp } from "../../utils/helpers/dateFormat";
import SwitchInputv2 from "../../components/Input/SwitchInputv2";
import { setCommunityId } from "../../redux/features/communitySlice";

import AddIcon from "../../assets/svg/addIcon";
import { useDispatch } from "react-redux";

export default function WarnNotesProperty() {
  const { id, pid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [totalWarnNotes, setTotalWarnNotes] = useState(0);
  const [warnNotes, setWarnNotes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [property, setProperty] = useState({});
  const [filterFields, setFilterFields] = useState({
    field: "status",
    fieldValue: "active",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [isLoadingArchive, setIsLoadingArchive] = useState(false);

  const getProperty = async () => {
    try {
      const { data } = await axios.get(
        `/api/property/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${pid}`
      );

      if (data.success) {
        setProperty(data.properties[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getWarnNotes = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/property/warnNote?propertyId=${pid}&pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=updatedAt&sortOrders=-1&filterFields=status&filterValues=${filterFields.fieldValue}`
      );

      if (data.success) {
        setTotalWarnNotes(data.matchedWarnNotesCount);
        setWarnNotes(data.warnNotes);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteWarnNoteProperty = async (wid) => {
    try {
      setIsLoadingDelete(true);

      const { data } = await axios.delete(
        `/api/property/warnNote?propertyId=${pid}&warnNoteIds=${wid}`
      );

      if (data.success) {
        toast.success(data.message);
        getWarnNotes();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingDelete(false);
    }
  };

  const updateWarnNoteStatus = async (wid) => {
    try {
      setIsLoadingArchive(true);

      const { data } = await axios.patch("/api/property/warnNote", {
        warnNoteId: wid,
        propertyId: pid,
        updateFields: {
          status: filterFields.fieldValue === "active" ? "archive" : "active",
        },
      });

      if (data.success) {
        toast.success(data.message);
        getWarnNotes();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoadingArchive(false);
    }
  };

  useEffect(() => {
    if (pid) getWarnNotes();
  }, [pid, currentPage, rowsPerPage, filterFields.fieldValue]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    if (pid) getProperty();
  }, [pid]);

  useEffect(() => {
    if (id) {
      dispatch(setCommunityId(id));
    }
  }, [id]);

  return (
    <div className="w-full">
      <Header
        title="Warn Notes"
        buttonText={
          isLoading
            ? " "
            : `${property?.streetNumber} ${property?.streetName}
          ${property?.addressLine2 ? property?.addressLine2 : ""} ${
                property?.city
              } ${property?.state}`
        }
        onClick={() => navigate(`/community/${id}/properties`)}
      />
      <div className="relative px-2 w-full lg:py-0 h-[94%] lg:h-[92%] xs:px-8 lg:px-8 flex flex-col space-y-4 ">
        <div className="overflow-auto flex-1  px-2 py-5 space-y-4">
          {isLoading && (
            <div className="flex flex-1 justify-center">
              <ClipLoader color={"#0080A2"} size={50} />
            </div>
          )}
          {!isLoading &&
            warnNotes.map((warnNote) => {
              return (
                <WarnNoteCard
                  note={warnNote.title}
                  description={warnNote.note}
                  date={formatTimestamp(warnNote.createdAt)}
                  wid={warnNote._id}
                  onClickHandler={deleteWarnNoteProperty}
                  onClickStatusHandler={updateWarnNoteStatus}
                  status={warnNote.status}
                  isLoadingArchive={isLoadingArchive}
                  isLoadingDelete={isLoadingDelete}
                />
              );
            })}

          {!isLoading && warnNotes.length === 0 && (
            <div className="text-primary-gray flex justify-center">
              No Warn Notes Found
            </div>
          )}
        </div>
        <div className="flex space-x-4">
          <Button
            innerText="Add New Warn Note"
            Icon={AddIcon}
            iconClass="h-[1rem]"
            extraClass={
              "shadow-button bg-primary-teal w-fit px-4 border-[1px] border-primary-teal  rounded-full py-1 text-[0.9rem] text-primary-white"
            }
            onClick={() =>
              navigate(`/community/${id}/property/${pid}/warn-notes/add`)
            }
          />
          <div className="flex items-center px-2">
            <SwitchInputv2
              isChecked={filterFields.fieldValue === "archive" ? true : false}
              onChange={(id, value) => {
                if (value === "add")
                  setFilterFields({
                    field: "status",
                    fieldValue: "archive",
                  });

                if (value === "remove")
                  setFilterFields({
                    field: "status",
                    fieldValue: "active",
                  });
              }}
            />
            <div className="text-primary-gray">Show Archive</div>
          </div>
        </div>
        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalWarnNotes}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />
        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
          }}
        />
      </div>
    </div>
  );
}
