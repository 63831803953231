import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";

import Pagination from "../../components/Pagination/Pagination";
import Header from "../../components/Header/Header";
import Input from "../../components/Input/Input";
import Select from "../../components/Input/SelectInput";
import { jobColumn } from "../../constants/TableColumns/Columns";
import Table from "../../components/Table/Table";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import axios from "../../utils/helpers/axios";

import JobIcon from "../../assets/svg/jobIcon";
import SearchIcon from "../../assets/svg/searchIcon";

export default function CompanyJobs() {
  const auth = useSelector((state) => state.auth);
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const navigate = useNavigate();

  const [jobs, setJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterFields, setFilterFields] = useState({
    field: "",
    value: "",
  });
  const [communitiesDropdown, setCommunitiesDropdown] = useState([]);
  const [community, setCommunity] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleChange = (id, data) => {
    setCommunity(data);
    setFilterFields({
      field: "communityId",
      value: data._id,
    });
  };

  const getCommunities = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        "/api/community/?sortFields=updatedAt&sortOrders=-1"
      );

      if (data.success) {
        setCommunitiesDropdown(data.communities);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getJobs = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/job/getListing?pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=updatedAt&sortOrders=-1&${
          filterFields.field
            ? filterFields.field + "=" + filterFields.value + "&"
            : ""
        }${searchQuery ? "searchQuery=" + searchQuery : ""}`
      );

      if (data.success) {
        setJobs(data.jobs);
        setTotalJobs(data.totalJobs);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getJobs();
  }, [currentPage, rowsPerPage, filterFields.value]);

  useEffect(() => {
    if (searchQuery.length === 0) getJobs();
    if (searchQuery.length > 2) getJobs();
  }, [searchQuery]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    getCommunities();
  }, []);

  return (
    <div className="w-full">
      <Header
        Icon={JobIcon}
        title="Jobs"
        buttonText={auth.user.company.companyName}
        onClick={() => {
          navigate("/companydashboard");
        }}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-4 h-[92%] mt-4"
      >
        <div className="flex gap-4">
          <Input
            iconClass="absolute top-[22%] left-4 text-secondary-gray "
            Icon={SearchIcon}
            placeholder="Search Job"
            inputFieldClass="placeholder:text-primary-gray"
            outerClass="w-[60%]"
            extraClass="relative border-gray border-[1px] py-[0.1rem] rounded-full px-12"
            onChange={(e) => onChange(e)}
          />
          <Select
            iconClass="absolute top-[22%] left-2 text-secondary-gray"
            placeholder="Select Community"
            inputFieldClass="placeholder:text-primary-gray"
            outerClass="w-[40%]"
            dropdownData={communitiesDropdown}
            fieldName="letterName"
            handleChange={handleChange}
            value={community?.letterName}
            id="community"
            extraClass="relative border-gray border-[1px] py-[0.1rem] rounded-full px-4"
          />
        </div>

        <div className="overflow-auto scrollbar min-h-[6rem] max-h-[84%] w-full flex flex-col items-center">
          {isLoading && <ClipLoader color={"#0080A2"} size={50} />}
          {!isLoading && jobs.length !== 0 && (
            <Table
              pageSize={rowsPerPage}
              COLUMNS={jobColumn}
              tableData={jobs}
            />
          )}
          {!isLoading && jobs.length === 0 && (
            <div className="flex flex-1 justify-center text-primary-gray">
              No Jobs found
            </div>
          )}
        </div>

        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalJobs}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />

        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowsPerPage) => {
            setRowsPerPage(rowsPerPage);
          }}
        />
      </div>
    </div>
  );
}
