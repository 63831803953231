import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useSelector } from "react-redux";

import { UserColumnsCompany } from "../../constants/TableColumns/Columns";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import Table from "../../components/Table/Table";
import Select from "../../components/Input/SelectInput";
import Pagination from "../../components/Pagination/Pagination";
import Header from "../../components/Header/Header";
import { ToggleSidebarContext } from "../../components/Layout/Layout";
import axios from "../../utils/helpers/axios";

import SearchIcon from "../../assets/svg/searchIcon";
import AddIcon from "../../assets/svg/addIcon";

export default function UserCompany() {
  const { user } = useSelector((store) => store.auth);
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [communities, setCommunities] = useState([]);
  const [community, setCommunity] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filterFields, setFilterFields] = useState({
    field: "",
    value: "",
  });
  const [searchQuery, setSearchQuery] = useState("");

  const onChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleChange = (id, data) => {
    setCommunity(data);
    setFilterFields({ field: "communitiesAllowed", value: data._id });
  };

  const getCommunities = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        "/api/community?sortFields=updatedAt&sortOrders=-1"
      );

      if (data.success) {
        setCommunities(data.communities);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getUsers = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(
        `/api/company/users?pageNum=${currentPage}&rowsPerPage=${rowsPerPage}&sortFields=updatedAt&sortOrders=-1&${
          filterFields.field
            ? "filterFields=" +
              filterFields.field +
              "&filterValues=" +
              filterFields.value +
              "&"
            : ""
        }${searchQuery ? "searchQuery=" + searchQuery : ""}`
      );

      if (data.success) {
        setUsers(data.users);
        setTotalUsers(data.matchedUserCount);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCommunities();
  }, []);

  useEffect(() => {
    getUsers();
  }, [currentPage, rowsPerPage, filterFields.value]);

  useEffect(() => {
    setCurrentPage(1);
  }, [rowsPerPage]);

  useEffect(() => {
    if (searchQuery.length === 0) getUsers();
    if (searchQuery.length > 2) getUsers();
  }, [searchQuery]);

  return (
    <div className="w-full">
      <Header
        title="User"
        buttonText={user?.company?.companyName}
        onClick={() => {
          navigate("/companydashboard");
        }}
      />
      <div
        onClick={() => setToggleSidebar(false)}
        className="px-8 flex flex-col gap-4 h-[92%] mt-4"
      >
        <div className="flex gap-4">
          <div className="flex gap-4 grow">
            <Input
              iconClass="absolute top-[22%] left-4 text-secondary-gray"
              Icon={SearchIcon}
              placeholder="Search User with Email"
              inputFieldClass="placeholder:text-primary-gray"
              outerClass="w-[60%]"
              onChange={(e) => onChange(e)}
              extraClass="relative w-full border-primary-gray border-[1px] py-[0.1rem] rounded-full px-12"
            />
            <Select
              iconClass="absolute top-[22%] left-2 text-secondary-gray"
              placeholder="Select Communitiy"
              inputFieldClass="placeholder:text-primary-gray"
              outerClass="w-[40%]"
              dropdownData={communities}
              id="community"
              fieldName="letterName"
              value={community?.letterName}
              handleChange={handleChange}
              extraClass="relative w-full border-primary-gray border-[1px] py-[0.1rem] rounded-full px-4"
            />
          </div>

          <Link to="/user-company/add">
            <Button
              Icon={AddIcon}
              iconClass="w-[1rem] h-[1rem]"
              extraClass="relative text-primary-white bg-primary-teal py-2 px-4 py-[.5rem] text-sm rounded-full"
              innerText="Add New User"
            />
          </Link>
        </div>

        <div className=" overflow-auto px-2 flex justify-center min-h-[6rem] max-h-[82%]">
          {isLoading && <ClipLoader color={"#0080A2"} size={50} />}
          {!isLoading && (
            <Table
              pageSize={rowsPerPage}
              COLUMNS={UserColumnsCompany}
              tableData={users}
            />
          )}
        </div>

        <Pagination
          isStatic={false}
          currentPage={currentPage}
          totalCount={totalUsers}
          pageSize={rowsPerPage}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
        />

        <Pagination
          isStatic={true}
          currentPage={rowsPerPage}
          onPageChange={(rowPerPage) => {
            setRowsPerPage(rowPerPage);
          }}
        />
      </div>
    </div>
  );
}
