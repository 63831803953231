import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import Modal from "react-responsive-modal";

import Header from "../Header/Header";
import LabelValueField from "../LabelValueField/LabelValueField";
import Button from "../Button/Button";
import axios from "../../utils/helpers/axios";
import { ToggleSidebarContext } from "../Layout/Layout";
import { setCommunityId } from "../../redux/features/communitySlice";

import PropertiesIcon from "../../assets/svg/propertiesIcon";
import OwnerIcon from "../../assets/svg/ownerIcon.svg";

export default function ChangeOwnerHistory() {
  const { id, pid } = useParams();
  const { setToggleSidebar } = useContext(ToggleSidebarContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [community, setCommunity] = useState({});
  const [property, setProperty] = useState({});
  const [ownerData, setOwnerData] = useState({
    firstOwnerFirstName: "",
    firstOwnerLastName: "",
    secondOwnerFirstName: "",
    secondOwnerLastName: "",
    mobileNumber: "",
    email: "",
    isMailingAddressDifferent: false,
  });
  const [alternateAddressData, setAlternateAddressData] = useState({
    firstOwnerFirstName: "",
    firstOwnerLastName: "",
    secondOwnerFirstName: "",
    secondOwnerLastName: "",
    streetNumber: "",
    streetName: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
  });
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingToken, setIsLoadingToken] = useState(false);
  const [openGenerateToken, setOpenGenerateToken] = useState(false);

  const onChangeOwnerData = (e) => {
    const { name, value } = e.target;

    setOwnerData((prev) => ({ ...prev, [name]: value }));
  };

  const onChangeAlternateData = (e) => {
    const { name, value } = e.target;

    setAlternateAddressData((prev) => ({ ...prev, [name]: value }));
  };

  const getMailingAddress = () => {
    if (ownerData?.isMailingAddressDifferent) {
      const mailingData = [
        alternateAddressData?.firstOwnerFirstName,
        alternateAddressData?.firstOwnerLastName,
        alternateAddressData?.streetNumber,
        alternateAddressData?.streetName,
        alternateAddressData?.city,
        alternateAddressData?.state,
        alternateAddressData?.zip,
      ];

      const check = mailingData?.every((item) => item);

      if (check)
        return `${alternateAddressData?.firstOwnerFirstName} ${
          alternateAddressData?.firstOwnerLastName
        }  ${
          alternateAddressData?.secondOwnerFirstName
            ? `& ${alternateAddressData?.secondOwnerFirstName} ${alternateAddressData?.secondOwnerLastName}`
            : ""
        } ${
          alternateAddressData?.streetNumber +
          " " +
          alternateAddressData?.streetName
        }
      ${alternateAddressData?.city}
      ${", " + alternateAddressData?.state}
      ${alternateAddressData?.zip}`;
    } else {
      const mailingData = [
        ownerData?.firstOwnerFirstName,
        ownerData?.firstOwnerLastName,
        property?.streetNumber,
        property?.streetName,
        property?.city,
        property?.state,
        property?.zip,
      ];
      const check = mailingData?.every((item) => item);

      if (check)
        return `${ownerData?.firstOwnerFirstName} ${
          ownerData?.firstOwnerLastName
        } ${
          ownerData?.secondOwnerFirstName
            ? ` &  ${ownerData?.secondOwnerFirstName} ${ownerData?.secondOwnerLastName}`
            : ""
        }  ${property?.streetNumber + " " + property?.streetName}
      ${property?.city}
      ${", " + property?.state}
      ${property?.zip} `;
    }
  };

  const getCommunity = async () => {
    try {
      const { data } = await axios.get(
        `/api/community/?sortFields=updatedAt&sortOrders=-1&filterFields=_id&filterValues=${id}`
      );

      if (data.success) {
        setCommunity(data.communities[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPropertyDetails = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.get(
        `/api/property/?communityId=${id}&sortFields=streetName&sortOrders=-1&filterFields=_id&filterValues=${pid}`
      );

      if (data.success) {
        // const owner = data.properties[0].propertyOwnerShip[0];

        setProperty(data.properties[0]);
        // setOwnerData(
        //   Object.keys(ownerData).reduce((acc, key) => {
        //     if (key in owner) acc[key] = owner[key];

        //     return acc;
        //   }, {})
        // );

        // if (owner.alternateAddress)
        //   setAlternateAddressData(owner.alternateAddress);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateOwnerDetails = async () => {
    try {
      setIsLoadingUpdate(true);

      const filteredOwnerData = Object.entries(ownerData).reduce(
        (acc, [key, val]) => {
          if (val !== "") acc[key] = val;
          return acc;
        },
        {}
      );

      const payload = {
        propertyId: pid,
        copyViolationToNewOwner: false,
        ownerDetails: {
          ...filteredOwnerData,
          ...(ownerData.isMailingAddressDifferent && {
            alternateAddress: Object.entries(alternateAddressData).reduce(
              (acc, [key, val]) => {
                if (val) acc[key] = val;
                return acc;
              },
              {}
            ),
          }),
        },
      };

      const { data } = await axios.post(
        "/api/property/changeOwnership",
        payload
      );

      if (data.success) {
        toast.success(data.message);
        addWarnNote();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message, {
        className: "custom-toast",
      });
    } finally {
      setIsLoadingUpdate(false);
    }
  };

  const addWarnNote = async () => {
    try {
      setIsLoading(true);

      const { data } = await axios.post("/api/property/warnNote", {
        propertyId: pid,
        title: "Ownership Changed",
        note: "Ownership Changed",
      });

      if (data.success) {
        toast.success(data.message);
        navigate(
          `/community/${id}/property-details/${pid}?OwnershipChange=true`
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const generateAccessToken = async () => {
    try {
      setIsLoadingToken(true);

      const { data } = await axios.post(
        "api/property/generatePropertyAccessToken",
        {
          propertyId: pid,
        }
      );

      if (data.success) {
        toast.success(data.message);
        setOpenGenerateToken(false);
        getPropertyDetails();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingToken(false);
    }
  };

  useEffect(() => {
    if (id) {
      getCommunity();
      dispatch(setCommunityId(id));
    }
  }, [id]);

  useEffect(() => {
    if (id && pid) getPropertyDetails();
  }, [id, pid]);

  return (
    <div className="w-full flex flex-col">
      <Header
        title="Change Ownership"
        buttonText={
          isLoading
            ? " "
            : `${property?.streetNumber} ${property?.streetName}
          ${property?.addressLine2 ? property?.addressLine2 : ""} ${
                property?.city
              } ${property?.state}`
        }
        onClick={() => navigate(`/community/${id}/properties`)}
      />

      <div
        onClick={() => setToggleSidebar(false)}
        className="flex flex-col grow gap-[4rem] px-[3rem] pt-[1.5rem] overflow-auto pb-4 scrollbar"
      >
        <div className="flex gap-[2rem] grow">
          <div className="flex flex-col gap-[2rem] w-1/2">
            <div className="opacity-[0.5]">
              <div className="flex gap-[0.3rem] text-primary-teal items-center border-b-[1px] border-primary-gray-light">
                <PropertiesIcon />
                <span>Property Address</span>
              </div>
              <div className="flex flex-col gap-[0.2rem] pt-[1rem]">
                <div className="flex justify-between">
                  <LabelValueField
                    label="Street No."
                    value={property?.streetNumber}
                  />
                  <div className=" flex items-center gap-[1rem]">
                    <input
                      type="checkbox"
                      checked={
                        property?.tenantOccupied === "yes" ? true : false
                      }
                    />
                    <div className={`text-sm text-primary-teal font-semibold`}>
                      Tenant Occupied
                    </div>
                  </div>
                </div>
                <LabelValueField
                  label="Street Name"
                  value={property?.streetName}
                />
                <LabelValueField
                  label="Address Line 2"
                  value={property?.addressLine2}
                />

                <div className="flex justify-between">
                  <LabelValueField label="City" value={property?.city} />
                  <LabelValueField label="State" value={property?.state} />
                  <LabelValueField label="Zip" value={property?.zip} />
                </div>
              </div>
            </div>
            <div>
              <div className="flex gap-[0.3rem] text-primary-teal items-center border-b-[1px] border-primary-gray-light">
                <img src={OwnerIcon} alt="" />
                <span>Owner's Details</span>
              </div>
              <div className="flex flex-col gap-[0.2rem] pt-[1rem]">
                <div className="flex gap-[1rem] justify-between ">
                  <LabelValueField
                    label="Owner's Token ID"
                    value={property?.ownershipAccessCode}
                    isEditable={true}
                    extraClass={"w-1/2"}
                  />
                  <Button
                    innerText={`Generate New Token ID`}
                    extraClass={`shadow-button bg-primary-white px-4 py-1 border-primary-teal rounded-full text-[0.9rem] text-primary-teal`}
                    onClick={() => setOpenGenerateToken(true)}
                  />
                </div>
                <div className="flex gap-[1.2rem] pt-[1rem]">
                  <div className="flex flex-col">
                    <span className="text-primary-teal text-[0.9rem] border-b-[1px] border-primary-gray-light">
                      First Owner
                    </span>
                    <div className="flex flex-col gap-[0.3rem] py-[0.5rem]">
                      <LabelValueField
                        label="First Name"
                        value={ownerData?.firstOwnerFirstName}
                        isEditable={true}
                        name="firstOwnerFirstName"
                        onChange={onChangeOwnerData}
                      />
                      <LabelValueField
                        label="Last Name"
                        value={ownerData?.firstOwnerLastName}
                        isEditable={true}
                        name="firstOwnerLastName"
                        onChange={onChangeOwnerData}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-primary-teal text-[0.9rem] border-b-[1px] border-primary-gray-light">
                      Second Owner
                    </span>
                    <div className="flex flex-col gap-[0.3rem] py-[0.5rem]">
                      <LabelValueField
                        label="First Name"
                        value={ownerData?.secondOwnerFirstName}
                        isEditable={true}
                        name="secondOwnerFirstName"
                        onChange={onChangeOwnerData}
                      />
                      <LabelValueField
                        label="Last Name"
                        value={ownerData?.secondOwnerLastName}
                        isEditable={true}
                        name="secondOwnerLastName"
                        onChange={onChangeOwnerData}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-primary-teal text-[0.9rem] border-b-[1px] border-primary-gray-light">
                    Details
                  </span>

                  <div className="flex flex-col gap-[0.3rem]">
                    <LabelValueField
                      label="Mobile No."
                      value={ownerData?.mobileNumber}
                      isEditable={true}
                      name="mobileNumber"
                      onChange={onChangeOwnerData}
                    />
                    <LabelValueField
                      label="Email"
                      value={ownerData?.email}
                      isEditable={true}
                      name="email"
                      onChange={onChangeOwnerData}
                    />
                    <LabelValueField
                      label="Mailing Address"
                      value={getMailingAddress()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${
              !ownerData?.isMailingAddressDifferent ? "opacity-0" : ""
            } w-1/2`}
          >
            <div className="flex flex-col">
              <div className="flex gap-[0.3rem] text-primary-teal items-center border-b-[1px] border-primary-gray-light">
                <img src={OwnerIcon} alt="" />
                <span>Alternate Address Details</span>
              </div>
              <div className="flex flex-col gap-[1rem] pt-[1rem]">
                <div className="flex gap-[1.2rem]">
                  <div className="flex flex-col w-1/2">
                    <span className="text-primary-teal text-[0.9rem] border-b-[1px] border-primary-gray-light">
                      First Owner
                    </span>

                    <div className="flex flex-col gap-[0.3rem] pt-[0.5rem]">
                      <LabelValueField
                        label="First Name"
                        value={alternateAddressData?.firstOwnerFirstName}
                        isEditable={true}
                        name={"firstOwnerFirstName"}
                        disabled={!ownerData?.isMailingAddressDifferent}
                        onChange={onChangeAlternateData}
                      />
                      <LabelValueField
                        label="Last Name"
                        value={alternateAddressData?.firstOwnerLastName}
                        isEditable={true}
                        name={"firstOwnerLastName"}
                        disabled={!ownerData?.isMailingAddressDifferent}
                        onChange={onChangeAlternateData}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-1/2">
                    <span className="text-primary-teal text-[0.9rem] border-b-[1px] border-primary-gray-light">
                      Second Owner
                    </span>
                    <div className="flex flex-col gap-[0.3rem] pt-[0.5rem]">
                      <LabelValueField
                        label="First Name"
                        value={alternateAddressData?.secondOwnerFirstName}
                        isEditable={true}
                        name={"secondOwnerFirstName"}
                        disabled={!ownerData?.isMailingAddressDifferent}
                        onChange={onChangeAlternateData}
                      />
                      <LabelValueField
                        label="Last Name"
                        value={alternateAddressData?.secondOwnerLastName}
                        isEditable={true}
                        name={"secondOwnerLastName"}
                        disabled={!ownerData?.isMailingAddressDifferent}
                        onChange={onChangeAlternateData}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="text-primary-teal text-[0.9rem] border-b-[1px] border-primary-gray-light">
                    Details
                  </span>
                  <div className="flex flex-col gap-[0.3rem] pt-[0.5rem]">
                    <LabelValueField
                      label="Street No."
                      value={alternateAddressData?.streetNumber}
                      isEditable={true}
                      name={"streetNumber"}
                      disabled={!ownerData?.isMailingAddressDifferent}
                      onChange={onChangeAlternateData}
                    />
                    <LabelValueField
                      label="Street Name"
                      value={alternateAddressData?.streetName}
                      isEditable={true}
                      name={"streetName"}
                      disabled={!ownerData?.isMailingAddressDifferent}
                      onChange={onChangeAlternateData}
                    />
                    <LabelValueField
                      label="Address Line 2"
                      value={alternateAddressData?.addressLine2}
                      isEditable={true}
                      name={"addressLine2"}
                      disabled={!ownerData?.isMailingAddressDifferent}
                      onChange={onChangeAlternateData}
                    />
                  </div>
                </div>
                <div className="flex justify-between">
                  <LabelValueField
                    label="City"
                    value={alternateAddressData?.city}
                    isEditable={true}
                    name={"city"}
                    disabled={!ownerData?.isMailingAddressDifferent}
                    onChange={onChangeAlternateData}
                  />
                  <LabelValueField
                    label="State"
                    value={alternateAddressData?.state}
                    isEditable={true}
                    name={"state"}
                    disabled={!ownerData?.isMailingAddressDifferent}
                    onChange={onChangeAlternateData}
                  />
                  <LabelValueField
                    label="Zip"
                    value={alternateAddressData?.zip}
                    isEditable={true}
                    name={"zip"}
                    disabled={!ownerData?.isMailingAddressDifferent}
                    onChange={onChangeAlternateData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-[0.5rem]">
            <div className="flex gap-[0.7rem]">
              <input
                type="checkbox"
                // checked={ownerData.isMailingAddressDifferent}
                // onChange={(e) =>
                //   setOwnerData((prev) => ({
                //     ...prev,
                //     isMailingAddressDifferent: e.target.checked,
                //   }))
                // }
              />
              <span className="text-primary-teal text-[0.9rem]">
                Copy Current Open Violations to new Owners
              </span>
            </div>
            <div className="flex gap-[0.7rem]">
              <input
                type="checkbox"
                checked={ownerData?.isMailingAddressDifferent}
                onChange={(e) =>
                  setOwnerData((prev) => ({
                    ...prev,
                    isMailingAddressDifferent: e.target.checked,
                  }))
                }
              />
              <span className="text-primary-teal text-[0.9rem]">
                Check this, if Owner’s Mailing Address is different from
                Property Address
              </span>
            </div>
          </div>
          <div className="flex gap-[1rem]">
            <Button
              innerText={"Cancel"}
              extraClass={
                "shadow-button bg-primary-white  border-[1px] border-primary-red rounded-full text-[0.9rem] text-primary-red px-[1.4rem] py-[0.5rem]"
              }
              onClick={() =>
                navigate(`/community/${id}/property-details/${pid}`)
              }
            />
            <Button
              type="submit"
              innerText="Submit"
              extraClass="shadow-button bg-primary-teal rounded-full text-[0.9rem] text-primary-white  px-[1.4rem] py-[0.5rem]"
              spinnerLight={true}
              disabled={isLoadingUpdate}
              isLoading={isLoadingUpdate}
              onClick={() => {
                updateOwnerDetails();
              }}
            />
          </div>
        </div>
      </div>

      <Modal
        closeOnOverlayClick={true}
        showCloseIcon={false}
        blockScroll={true}
        open={openGenerateToken}
        onClose={() => {
          setOpenGenerateToken(false);
        }}
        center
      >
        <div className="shadow-inputShadow rounded-xl p-4 flex flex-col gap-y-4">
          <div className="text-secondary-gray text-sm">
            Are you sure you wish to generate a new Token ID? An email with the
            new ID will automatically be sent to the email address shown. This
            will also change the way the owner accesses the Owner Access page
          </div>

          <div className="flex justify-end w-full text-sm gap-4">
            <Button
              onClick={() => setOpenGenerateToken(false)}
              innerText="Cancel"
              extraClass="shadow-button border-[0.5px] border-primary-red bg-primary-white rounded-full py-2 px-4 text-primary-red"
            />
            <Button
              onClick={generateAccessToken}
              isLoading={isLoadingToken}
              disabled={isLoadingToken}
              innerText="Yes"
              extraClass="shadow-button border-[0.5px] border-primary-teal bg-primary-teal rounded-full py-2 px-4 text-primary-white"
              spinnerLight={true}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
